export default {
  en: {
    translation: {
      'community.prologue1': '1egDAO started from',
      'community.prologue2': '1ethgame.',
      'community.prologue3': 'We invested 100eth',
      'community.prologue4': 'solely in the potential of our crew members.',
      'community.selecting1': 'The core of 1egDAO is our community of selected members.',
      'community.selecting2': '',
      'community.selecting3': 'Our DAO-operated Selection Committee carefully selects those who share the same vision.',
      'community.selecting4': '',
      'community.potential.title1': 'A Community of Diverse Professionals',
      'community.potential.title2': '',
      'community.potential.desc1': 'Web3 projects will continue to branch into new and different fields.',
      'community.potential.desc2': '',
      'community.potential.desc3': 'We build Web3 communities with diverse backgrounds and professional expertise.',
      'community.potential.desc4': '',
      'community.locallybased.title1': 'An IRL Community',
      'community.locallybased.title2': '',
      'community.locallybased.desc1': 'Trust begins at contact.',
      'community.locallybased.desc2': 'Amidst the “trustless” Web3 world, we connect local communities and build a foundation of trust.',
      'trust.protocol.desc1': 'Building Trust on the Blockchain',
      'trust.protocol.desc2': '',
      'trust.protocol.desc3': '',
      'trust.protocol.desc4': 'All DAO activity - from the selection process and SBT minting, to community participation, project contribution and more - will be stored on the blockchain.',
      'trust.protocol.desc5': '',
      'trust.protocol.desc6': '',
      'trust.protocol.desc7': '',
      'trust.proofOfTrust.desc1': 'We contribute to causes and work with others based on trust.',
      'trust.proofOfTrust.desc2': '',
      'trust.proofOfTrust.desc3': 'With “Proof of Trust,” trusted members will be rewarded with more opportunities, and trustworthy projects will have access to more resources.',
      'trust.proofOfTrust.desc4': '',
      'trust.proofOfTrust.desc5': '',
      'trust.bridge.desc1': 'Through 1egDAO members building on',
      'trust.bridge.desc2': 'the 1eg Trust Protocol',
      'trust.bridge.desc3': 'we bridge Web2 and Web3 projects.',
      'trust.launchpad.desc1': 'Diverse members, groups, and projects',
      'trust.launchpad.desc2': 'are brought together by one protocol.',
      'trust.launchpad.desc3': '',
      'trust.launchpad.desc4': 'By connecting with other local communities, we build Web3 projects that go beyond borders.',
      'building.description.title1': 'To build a Web3 project',
      'building.description.title2': 'is to build a community.',
      'building.description.desc1': 'Tech is ubiquitous.',
      'building.description.desc2': '',
      'building.description.desc3': '1egDAO has a community of Web3 builders connected by Trust Protocol.',
      'building.description.desc4': '',
      'building.description.desc5': '',
      'sbt.benefit.selectedMembership1': 'Selective SBTs are issued only to selected members. They grant community access and unlock membership benefits.',
      'sbt.benefit.selectedMembership2': '',
      'sbt.benefit.tokenOfTrust1': 'The Selective SBT mints the member’s potential on the blockchain and marks the start of 1eg Trust Protocol.',
      'sbt.benefit.tokenOfTrust2': '',
      'sbt.benefit.customGenerativeArt1': 'Unique SBT artwork made by a generative algorithm will be customized to each individual member.',
      'sbt.benefit.customGenerativeArt2': '',
    }
  },
  ko: {
    translation: {
      'community.prologue1': '1ethgame,',
      'community.prologue2': '1egDAO의 시작입니다.',
      'community.prologue3': '우리는 100eth를',
      'community.prologue4': '크루들의 포텐셜에 투자하며 시작했습니다.',
      'community.selecting1': '1egDAO의 코어,',
      'community.selecting2': '선별된 커뮤니티',
      'community.selecting3': 'DAO의 비전에 공감하는 사람들을',
      'community.selecting4': '크루들로 구성된 선발위원회가 선별합니다.',
      'community.potential.title1': '다양한 영역의 인재들로',
      'community.potential.title2': '구성된 커뮤니티',
      'community.potential.desc1': '앞으로 많은 영역에서',
      'community.potential.desc2': '웹3 프로젝트가 진행될 것입니다.',
      'community.potential.desc3': '다양한 분야의 지식을 가진',
      'community.potential.desc4': '웹3 커뮤니티를 만들어갑니다.',
      'community.locallybased.title1': 'In Real Life,',
      'community.locallybased.title2': '로컬 기반의 커뮤니티',
      'community.locallybased.desc1': '신뢰의 시작은 만남입니다.',
      'community.locallybased.desc2': '신뢰할 수 없는 웹3 세상 위에서 선별된 전문분야, 프로젝트, 관심 영역의 로컬 커뮤니티를 직접 만나며 신뢰의 기반을 만들어갑니다.',
      'trust.protocol.desc1': '커뮤니티 활동, 관계,',
      'trust.protocol.desc2': '프로젝트 경험에 대한',
      'trust.protocol.desc3': '블록체인 기반 신뢰 거버넌스',
      'trust.protocol.desc4': '셀렉션 프로세스, SBT 민팅,',
      'trust.protocol.desc5': '1eg 커뮤니티 활동, 프로젝트 진행 등',
      'trust.protocol.desc6': '신뢰를 만들어 가는 모든 활동을',
      'trust.protocol.desc7': '블록체인 위에 기록합니다.',
      'trust.proofOfTrust.desc1': '우리는 신뢰가 있는 곳에 기여하고,',
      'trust.proofOfTrust.desc2': '신뢰하는 사람과 일합니다.',
      'trust.proofOfTrust.desc3': '신뢰 거버넌스 안에서',
      'trust.proofOfTrust.desc4': '신뢰가 높은 프로젝트와 사람에게',
      'trust.proofOfTrust.desc5': '더 많은 기회와 지원이 제공될 것입니다.',
      'trust.bridge.desc1': '신뢰 프로토콜을 통해',
      'trust.bridge.desc2': '1egDAO 멤버들은 커뮤니티가 되고,',
      'trust.bridge.desc3': '다양한 프로젝트와 연결됩니다.',
      'trust.launchpad.desc1': '다양한 사람, 조직, 프로젝트가',
      'trust.launchpad.desc2': '같은 신뢰 프로토콜 위에서',
      'trust.launchpad.desc3': '서로 다른 로컬 커뮤니티와 연결되며,',
      'trust.launchpad.desc4': '국경을 넘어 함께 웹3 프로젝트를 빌딩합니다.',
      'building.description.title1': '웹3 프로젝트 빌딩은',
      'building.description.title2': '커뮤니티 빌딩입니다.',
      'building.description.desc1': '기술만 있는 팀은',
      'building.description.desc2': '어디에나 있습니다.',
      'building.description.desc3': '1egDAO에는',
      'building.description.desc4': 'Trust Protocol로 연결된',
      'building.description.desc5': '웹3 빌더들이 있습니다.',
      'sbt.benefit.selectedMembership1': '선별된 1egDAO의 멤버임을 증명하며,',
      'sbt.benefit.selectedMembership2': '커뮤니티 혜택을 제공하는 멤버십 패스',
      'sbt.benefit.tokenOfTrust1': '1eg Trust Protocol의 시작',
      'sbt.benefit.tokenOfTrust2': '블록체인 위에 꿈과 포텐셜을 기록하는 과정',
      'sbt.benefit.customGenerativeArt1': '나의 선택과 알고리즘이 만들어낸',
      'sbt.benefit.customGenerativeArt2': '세상에 단 하나뿐인 SBT 아트워크',
    }
  },
}
