import { useMediaQuery } from "usehooks-ts"
import { useEffect, useRef } from "react"

export default function SplashPage() {
  const isMobile = !useMediaQuery('(min-width: 768px)')
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    videoRef.current?.play().then()
  }, [])

  const videoAsset = isMobile ? '1egdao-artwork-mobile-221223.mp4' : '1egdao-artwork-web.mp4'
  const videoSrc = `${process.env.PUBLIC_URL}/assets/${videoAsset}`
  const videoFallbackImage = isMobile ? '1egdao-artwork-mobile-frame001.png' : '1egdao-artwork-web-frame001.png'
  const videoFallbackImageSrc = `${process.env.PUBLIC_URL}/assets/${videoFallbackImage}`

  if (isMobile) {
    return (
      <div className="relative absolute inset-0">
        <figure className="relative w-screen" style={{ height: 'calc(100vh - var(--header-height))' }}>
          <div className="absolute h-full">
            <img className="object-cover h-full" src={videoFallbackImageSrc} alt="artwork" />
          </div>
          <video ref={videoRef} className="absolute w-full h-full object-cover"
                 preload="metadata" muted loop playsInline>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </figure>
      </div>
    )
  }

  return (
    <div className="relative absolute inset-0">
      <figure className="relative w-screen" style={{ height: 'calc(100vh - var(--header-height))' }}>
        <div className="absolute h-full">
          <img className="h-full object-cover" src={videoFallbackImageSrc} alt="artwork" />
        </div>
        <video ref={videoRef} className="absolute w-full h-full object-cover"
               preload="metadata" muted loop playsInline>
          <source src={videoSrc} type="video/mp4" />
        </video>
      </figure>
      <div className="absolute inset-0 opacity-70">
        <div className="absolute inset-0 opacity-70 bg-white" />
        <div className="bgGridY absolute inset-0">
          <div className="bgGridX absolute inset-0" />
        </div>
      </div>
    </div>
  )
}
