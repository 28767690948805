import { createBrowserRouter } from 'react-router-dom'
import Home from '@src/pages/home/Home'
import Apply from '@src/pages/apply/Apply'
import Season1Application from '@src/pages/apply/season-1-application/Season1Application'
import AppTemplate from '@src/pages/AppTemplate'
import ApplyForOg from '@src/pages/apply/ApplyForOg'
import OgApplication from '@src/pages/apply/og-application/OgApplication'


const router = createBrowserRouter([{
  path: '',
  element: <AppTemplate />,
  children: [{
    path: '/',
    element: <Home />,
  }, {
    path: '/apply',
    element: <Apply />,
  }, {
    path: '/apply/season-1-application',
    element: <Season1Application />,
  }, {
    path: '/og-apply',
    element: <ApplyForOg />,
  }, {
    path: '/apply/og-application',
    element: <OgApplication />,
  }],
}])

export default router
