import { useLayoutEffect, useRef, useState } from "react"
import OegDao from "../../components/logos/oegdao/OegDao"
import { useWindowSize } from "usehooks-ts"
import gsap from 'gsap'

const HEADER_HEIGHT = 80
const SCROLL_TRIGGER_TOP = HEADER_HEIGHT
const LOGO_END_CENTER_POS_Y = HEADER_HEIGHT / 2
const LOGO_END_WIDTH = 120
const LOGO_HEIGHT_WIDTH_RATIO = 489 / 1858
const LOGO_END_LEFT_POS_X = 40

export default function AnimationLogo() {
  const logoRef = useRef(null)
  const { width: windowInnerWidth, height: windowInnerHeight } = useWindowSize()

  const [scrollTop, setScrollTop] = useState(window.scrollY)
  const [scrollTriggerBottom, setScrollTriggerBottom] = useState(windowInnerHeight ? windowInnerHeight : window.innerHeight)
  const [scrollTriggerProgress, setScrollTriggerProgress] = useState(Math.min(scrollTop / (scrollTriggerBottom - SCROLL_TRIGGER_TOP), 1))
  const [logoInitialWidth, setLogoInitialWidth] = useState(windowInnerWidth * 0.6)
  const [logoInitialHeight, setLogoInitialHeight] = useState(logoInitialWidth * LOGO_HEIGHT_WIDTH_RATIO)
  const [logoInitialCenterPosY, setLogoInitialCenterPosY] = useState((windowInnerHeight - HEADER_HEIGHT) / 2 + HEADER_HEIGHT)
  const [logoInitialLeftPosX, setLogoInitialLeftPosX] = useState((windowInnerWidth - logoInitialWidth) / 2)
  const [logoEndScale, setLogoEndScale] = useState(LOGO_END_WIDTH / logoInitialWidth)
  const [scale, setScale] = useState(Math.max(1 - (1 - logoEndScale) * scrollTriggerProgress, logoEndScale))
  const [translateX, setTranslateX] = useState(logoInitialLeftPosX - (logoInitialLeftPosX - LOGO_END_LEFT_POS_X) * scrollTriggerProgress)
  const [translateY, setTranslateY] = useState(-1 * ((logoInitialCenterPosY - LOGO_END_CENTER_POS_Y) * scrollTriggerProgress - ((logoInitialHeight - LOGO_END_WIDTH * LOGO_HEIGHT_WIDTH_RATIO) / 2 * scrollTriggerProgress)))

  useLayoutEffect(() => {
    gsap.to(logoRef.current, 0.1, {
      opacity: 1,
      delay: 0.5,
    })
  }, [logoRef])

  useLayoutEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  useLayoutEffect(() => {
    const _logoInitialWidth = windowInnerWidth * 0.6
    const _logoInitialHeight = _logoInitialWidth * LOGO_HEIGHT_WIDTH_RATIO
    const _scrollTriggerProgress = Math.min(scrollTop / (scrollTriggerBottom - SCROLL_TRIGGER_TOP), 1)
    const _logoInitialCenterPosY = (windowInnerHeight - HEADER_HEIGHT) / 2 + HEADER_HEIGHT
    const _logoInitialLeftPosX = (windowInnerWidth - _logoInitialWidth) / 2
    const _logoEndScale = LOGO_END_WIDTH / _logoInitialWidth

    setScrollTriggerBottom(windowInnerHeight)
    setScrollTriggerProgress(_scrollTriggerProgress)
    setLogoInitialWidth(_logoInitialWidth)
    setLogoInitialHeight(_logoInitialHeight)
    setLogoInitialCenterPosY(_logoInitialCenterPosY)
    setLogoInitialLeftPosX(_logoInitialLeftPosX)
    setLogoEndScale(_logoEndScale)

    setScale(Math.max(1 - (1 - _logoEndScale) * _scrollTriggerProgress, _logoEndScale))
    setTranslateX(_logoInitialLeftPosX - (_logoInitialLeftPosX - LOGO_END_LEFT_POS_X) * _scrollTriggerProgress)
    setTranslateY(-1 * ((_logoInitialCenterPosY - LOGO_END_CENTER_POS_Y) * _scrollTriggerProgress - ((_logoInitialHeight - LOGO_END_WIDTH * LOGO_HEIGHT_WIDTH_RATIO) / 2 * _scrollTriggerProgress)))
  }, [windowInnerWidth, windowInnerHeight, scrollTop])

  return (
    <div className="invisible md:visible fixed top-[80px] left-0 right-0 bottom-0 pointer-events-none z-30">
      <div className="flex w-full h-full items-center bg-transparent">
        <div className="w-full">
          <div ref={logoRef} className="origin-top-left opacity-0" style={{
            width: `${logoInitialWidth}px`,
            transform: `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`
          }}>
            <OegDao />
          </div>
        </div>
      </div>
    </div>
  )
}
