import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import cx from 'classnames'
import TwitterLogo from '@src/components/logos/twitter/TwitterLogo'
import { Squash as Hamburger } from 'hamburger-react'
import { HashLink } from 'react-router-hash-link'
import OegDao from '@src/components/logos/oegdao/OegDao'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useSearchParams, useNavigate, Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from "usehooks-ts"
import { PlusIcon } from '@heroicons/react/20/solid'
import config from '@src/config'
import Web3ConnectButton from '@src/pages/global/Web3ConnectButton'

interface HeaderProps {
  useLogo?: boolean
}

export default function Header(props: HeaderProps = { useLogo: false }) {
  const isMobile = !useMediaQuery('(min-width: 768px)')
  const location = useLocation()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [isEnglish, setIsEnglish] = useState(searchParams.get('lng') === 'en')
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false)
  const [isSideMenuAboutOpened, setIsSideMenuAboutOpened] = useState(false)

  window.addEventListener("load", function(event) {
    ScrollTrigger.refresh()
  })

  if (isMobile) {
    return (
      <header className="sticky flex flex-row top-0 z-20 h-[80px] bg-white shadow">
        <nav className="relative w-full h-full md:hidden">
          <div className="relative w-full h-full flex justify-between items-center pl-6 pr-2">
            <div className="md:invisible w-[80px] flex justify-center items-center">
              <OegDao className="w-full" />
            </div>
            <div className="flex justify-center items-center space-x-2">
              <a href="https://twitter.com/1egdao" target="_blank" rel="noopener noreferrer">
                <TwitterLogo width="24"
                             className="cursor-pointer transition ease-in-out duration-300 hover:scale-110" />
              </a>
              <div className="p-1 cursor-pointer transition ease-in-out duration-300 rounded">
                <Hamburger toggled={isMobileMenuOpened} onToggle={setIsMobileMenuOpened} size={24} color='#4545C9' />
              </div>
            </div>
          </div>
          <ul className={cx("absolute top-[80px] inset-x-0 bg-white scale-y-0 origin-top opacity-0 transition ease-in-out duration-500", {
            'scale-y-100 opacity-100': isMobileMenuOpened,
          })}>
            {(() => {
              const outerClass = 'h-20 text-[20px] font-medium flex items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer'
              const innerClass = 'mx-12 px-4 w-full h-full flex items-center'
              return (
                <>
                  <li className={outerClass}>
                    <HashLink to="/#top" scroll={(el) => {
                      setIsMobileMenuOpened(false)
                      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }} className={cx(innerClass, 'border-b border-[#CCCCEC]')}>
                      About
                    </HashLink>
                  </li>
                  <li className={outerClass}>
                    <HashLink to="/#community" scroll={(el) => {
                      setIsMobileMenuOpened(false)
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }} className={cx(innerClass, 'border-b border-[#CCCCEC]')}>
                      Community
                    </HashLink>
                  </li>
                  <li className={outerClass}>
                    <HashLink to="/#trust-protocol" scroll={(el) => {
                      setIsMobileMenuOpened(false)
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }} className={cx(innerClass, 'border-b border-[#CCCCEC]')}>
                      Trust Protocol
                    </HashLink>
                  </li>
                  <li className={cx('mb-[3vw]', outerClass)}>
                    <HashLink to="/#membership" scroll={(el) => {
                      setIsMobileMenuOpened(false)
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }} className={cx(innerClass, 'border-b border-[#CCCCEC]')}>
                      Membership
                    </HashLink>
                  </li>
                  <li className={cx('mb-[3vw]', outerClass)}>
                    <div className={innerClass}>
                      <a className={cx('cursor-pointer pr-1', {
                        'text-black': !isEnglish,
                        'text-[#AEAEAE]': isEnglish,
                      })} href="?lng=ko">
                        KO
                      </a>
                      |
                      <a className={cx('cursor-pointer pl-1', {
                        'text-[#AEAEAE]': !isEnglish,
                        'text-black': isEnglish,
                      })} href="?lng=en">
                        EN
                      </a>
                    </div>
                  </li>
                </>
              )
            })()}
          </ul>
        </nav>
      </header>
    )
  }

  return (
    <>
      <header className="sticky flex flex-row top-0 z-20 h-[80px] bg-white shadow">
        <div className="w-full flex items-center justify-between">
          {props.useLogo ? (
            <div className="ml-12 flex items-center">
              <Link to="/" reloadDocument>
                <OegDao className="w-[120px]" />
              </Link>
            </div>
          ) : (
            <div />
          )}
          <nav className="hidden md:flex justify-end items-center h-full">
            <ul className="flex justify-evenly items-center space-x-8 mr-2 lg:mr-10 text-[13px]"
                style={{ fontWeight: 500 }}>
              <li className="h-full">
                <Link to="/apply" reloadDocument>
                  <div className="w-[150px] h-[45px] border border-1 border-[#4545C9] rounded-[30px] text-[#4545C9] text-[20px] font-semibold transition-colors duration-300 hover:bg-[#4545C9] hover:text-white flex justify-center items-center">
                    APPLY
                  </div>
                </Link>
              </li>
              <li>
                <Web3ConnectButton />
              </li>
              <li>
                <Hamburger toggled={isSideMenuOpened} onToggle={setIsSideMenuOpened} size={24} color='#4545C9' />
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <Transition
        show={isSideMenuOpened}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed top-[81px] bottom-0 right-0 w-[400px] flex flex-col justify-between border-l border-[#CCCCEC] bg-white z-50">
          <ul className="grow w-full pt-4">
            <li className="h-20 text-[20px] font-medium w-full flex justify-between items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
              {location.pathname === '/' ? (
                <div
                  className="mx-12 px-4 w-full h-full font-semibold flex items-center border-b border-[#CCCCEC]"
                  onClick={() => setIsSideMenuAboutOpened(!isSideMenuAboutOpened)}
                >
                  ABOUT
                  <div className="absolute right-14 w-6 h-6">
                    <PlusIcon fill="#CCCCEC" />
                  </div>
                </div>
              ) : (
                <Link to="/" className="mx-12 px-4 w-full h-full font-semibold flex items-center border-b border-[#CCCCEC]" reloadDocument>
                  ABOUT
                </Link>
              )}
            </li>
            <li>
              <Transition
                show={isSideMenuAboutOpened}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ul>
                  <li className="h-14 text-[16px] font-medium w-full flex justify-between items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
                    <div className="mx-12 px-4 w-full h-full flex items-center" onClick={() => {
                      const el = document.querySelector('#community')
                      // @ts-ignore
                      const progress = window.pinTl.progress()
                      // @ts-ignore
                      let top = cumulativeOffset(el).top - 80
                      if (progress > 0 && progress < 1) {
                        // @ts-ignore
                        top += getScrollPosition(window.pinTl, 0)
                      }
                      window.scrollTo({
                        top: top,
                        behavior: 'smooth',
                      })
                    }}>
                      Community
                    </div>
                  </li>
                  <li className="h-14 text-[16px] font-medium w-full flex justify-between items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
                    <div className="mx-12 px-4 w-full h-full flex items-center" onClick={() => {
                      const el = document.querySelector('#trust-protocol')
                      // @ts-ignore
                      const progress = window.pinTl.progress()
                      // @ts-ignore
                      let top = cumulativeOffset(el).top - 80
                      if (progress > 0 && progress < 1) {
                        // @ts-ignore
                        top += getScrollPosition(window.pinTl, 0)
                      }
                      window.scrollTo({
                        top: top,
                        behavior: 'smooth',
                      })
                    }}>
                      Trust Protocol
                    </div>
                  </li>
                  <li className="h-14 text-[16px] font-medium w-full flex justify-between items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
                    <div className="mx-12 px-4 w-full h-full flex items-center border-b border-[#CCCCEC]" onClick={() => {
                      const el = document.querySelector('#membership')
                      // @ts-ignore
                      const progress = window.pinTl.progress()
                      // @ts-ignore
                      let top = cumulativeOffset(el).top + getScrollPosition(window.pinTl, 1) - getScrollPosition(window.pinTl, 0) - 80
                      if (progress > 0 && progress < 1) {
                        // @ts-ignore
                        top += getScrollPosition(window.pinTl, 0)
                      }
                      window.scrollTo({
                        top: top,
                        behavior: 'smooth',
                      })
                    }}>
                      Membership
                    </div>
                  </li>
                </ul>
              </Transition>
            </li>
            {/*<li className="h-20 text-[20px] font-medium flex items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">*/}
            {/*  <HashLink to="/#faq" scroll={(el) => {*/}
            {/*    setIsSideMenuOpened(false)*/}
            {/*    el.scrollIntoView({ behavior: 'smooth', block: 'start' })*/}
            {/*  }} className="mx-12 px-4 w-full h-full font-semibold flex items-center border-b border-[#CCCCEC]">*/}
            {/*    FAQ*/}
            {/*  </HashLink>*/}
            {/*</li>*/}
            {/*<li className="h-20 text-[20px] font-medium flex items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">*/}
            {/*  <HashLink to="/#docs" scroll={(el) => {*/}
            {/*    setIsSideMenuOpened(false)*/}
            {/*    el.scrollIntoView({ behavior: 'smooth', block: 'start' })*/}
            {/*  }} className="mx-12 px-4 w-full h-full font-semibold flex items-center border-b border-[#CCCCEC]">*/}
            {/*    DOCS*/}
            {/*  </HashLink>*/}
            {/*</li>*/}
            <li className="h-20 text-[20px] font-medium flex items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
              <a href={config.twitter} target="_blank" rel="noopener noreferrer" className="mx-12 px-4 w-full h-full font-semibold flex items-center border-b border-[#CCCCEC]">
                Twitter
              </a>
            </li>
            <li className="h-20 text-[20px] font-medium flex items-center transition ease-in-out duration-300 hover:bg-gray-100 cursor-pointer">
              <HashLink to={config.medium} scroll={(el) => {
                setIsSideMenuOpened(false)
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }} className="mx-12 px-4 w-full h-full font-semibold flex items-center">
                Medium
              </HashLink>
            </li>
          </ul>
          <div className="grow-0 text-[20px] mx-12 px-4 w-full h-20 font-semibold flex items-center border-b border-[#CCCCEC] cursor-default">
            <a className={cx('pr-1', {
              'text-black cursor-default': !isEnglish,
              'text-[#AEAEAE] cursor-pointer': isEnglish,
            })} onClick={() => {
              if (!isEnglish) {
                return
              }

              setIsSideMenuOpened(false)
              navigate('?lng=ko', {
                replace: true,
              })
            }}>
              KO
            </a>
            |
            <a className={cx('pl-1', {
              'text-[#AEAEAE] cursor-pointer': !isEnglish,
              'text-black cursor-default': isEnglish,
            })} onClick={() => {
              if (isEnglish) {
                return
              }

              setIsSideMenuOpened(false)
              navigate('?lng=en', {
                replace: true,
              })
            }}>
              EN
            </a>
          </div>
        </div>
      </Transition>
    </>
  )
}

function cumulativeOffset(element) {
  let top = 0, left = 0
  do {
    top += element.offsetTop  || 0
    left += element.offsetLeft || 0
    element = element.offsetParent
  } while(element)

  return {
    top: top,
    left: left
  }
}

function getScrollPosition(animation, progress) {
  let p = gsap.utils.clamp(0, 1, progress || 0),
    st = animation.scrollTrigger,
    containerAnimation = st.vars.containerAnimation
  if (containerAnimation) {
    let time = st.start + (st.end - st.start) * p
    st = containerAnimation.scrollTrigger
    return st.start + (st.end - st.start) * (time / containerAnimation.duration())
  }
  return st.start + (st.end - st.start) * p
}
