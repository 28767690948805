import { observer } from 'mobx-react'
import { Link, useSearchParams } from 'react-router-dom'
import ApplicationSection1 from './ApplicationSection1'
import ApplicationSection2 from './ApplicationSection2'
import ApplicationSection3 from './ApplicationSection3'
import icons from './icons'
import classNames from 'classnames'
import React from 'react'
import { contentBorderClass } from '@src/Styles'


const Season1Application = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSection = searchParams.get('section')
  switch(currentSection) {
    case 'success':
      return <SubmitSuccess />
    case '3':
      return <ApplicationSection3 />
    case '2':
      return <ApplicationSection2 />
    case '1':
    default:
      return <ApplicationSection1 />
  }
}

function SubmitSuccess() {
  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <section className={classNames("bg-white relative p-[80px]", contentBorderClass)}>
          <div className="relative mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center items-center">
            <h3 className="text-center font-bold mb-16 text-[#4545C9]">
              <span className="text-[30px]">1egDAO, Seoul - Season 1</span><br />
              <span className="text-[50px]">지원서가 성공적으로 제출되었습니다.</span>
            </h3>
            <div className="grid grid-cols-5 gap-x-8 gap-y-8 px-8 mb-20">
              <div className="col-start-1 self-center flex justify-end">
                <img className="object-cover w-[100px] h-[100px]" src={icons.PostboxIcon} />
              </div>
              <div className="col-start-2 col-span-full space-y-4">
                <div className="text-[24px] font-bold">
                  지원 결과 및 추후 프로세스는 2주 이내로 입력하신 이메일 주소로 안내될 예정입니다.
                </div>
                <div className="text-[18px] font-regular">
                  (*지원 현황에 따라 결과 안내가 지연될 수 있습니다.)
                </div>
              </div>

              <div className="col-start-1 self-center flex justify-end">
                <img className="object-cover w-[100px] h-[100px]" src={icons.PostIcon} />
              </div>
              <div className="col-start-2 col-span-full space-y-4">
                <div className="text-[24px] font-bold">
                  지원서 제출 후 2일 이내로 추천인 (초대코드를 전달해 준 사람)이 추천서를 작성할 경우, 심사 과정에서 가산점이 있습니다.
                </div>
                <div className="text-[18px] font-regular">
                  (*추천서 작성을 위해 추천인에게 별도 안내를 드릴 예정입니다.)
                </div>
              </div>
            </div>
            <Link to="/">
              <button
                type="submit"
                className="relative px-20 py-4 rounded-[40px] text-white text-[24px] font-bold bg-[#4545C9] flex justify-center items-center"
              >
                메인 페이지로 가기
              </button>
            </Link>
          </div>
        </section>
      </div>
    </div>
  )
}

export default observer(Season1Application)
