import React, { useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import highfive from './highfive.png'
import CheckTermsAgreeModal from './modals/CheckTermsAgreeModal'
import { observer } from 'mobx-react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { useModal, useSIWE } from 'connectkit'
import { useMediaQuery } from 'usehooks-ts'
import { useAccount } from 'wagmi'
import { ErrorMessage } from '@src/components/ErrorMessage/ErrorMessage'
import CheckQuizModal from '@src/pages/apply/modals/CheckQuizModal'

function ApplyForOg() {
  const isMobile = !useMediaQuery('(min-width: 768px)')
  const { applicationStore } = useRootStore()
  const navigate = useNavigate();
  const [applicationCount, setApplicationCount] = useState(0)
  const [isCheckInvitationCodeModalOpen, setIsCheckInvitationCodeModalOpen] = useState(false)
  const [isTermsAgreeModalOpen, setIsTermsAgreeModalOpen] = useState(false)
  const [invitationButtonClicked, setInvitationButtonClicked] = useState(false)
  const { open, setOpen } = useModal()  // wallet login modal
  const { signedIn } = useSIWE()
  const { address, isConnecting, isDisconnected } = useAccount()
  const [inputCodeButtonError, setInputCodeButtonError] = useState<string>('')

  useEffect(() => {
    async function getCount() {
      const res = await axiosClient.get('/api/v1/application/count')
      setApplicationCount(res.data.data)
    }

    getCount().then().catch(console.error)
  }, [])

  useEffect(() => {
    if (invitationButtonClicked && signedIn) {
      setIsCheckInvitationCodeModalOpen(true)
    }
  }, [signedIn])

  const handleInputCodeButtonClick = async () => {
    setInputCodeButtonError('')

    if (!signedIn) {
      setInvitationButtonClicked(true)
      setOpen(true)
      return
    }

    if (!address) {
      setInputCodeButtonError('로그인 후 이용해 주세요')
      return
    }

    setIsCheckInvitationCodeModalOpen(true)
  }

  if (isMobile) {
    return <NotSupportedMessage />
  }

  return (
    <>
      <CheckQuizModal
        isModalOpen={isCheckInvitationCodeModalOpen}
        setIsModalOpen={setIsCheckInvitationCodeModalOpen}
        onSubmitConfirmed={invitationCode => {
          // delay for modal transition
          // open new modal immediately raises scroll freeze bug
          applicationStore.invitationCode = invitationCode
          setTimeout(() => {
            setIsTermsAgreeModalOpen(true)
          }, 300)
        }}
      />
      <CheckTermsAgreeModal
        buttonLabel="OG WL 신청하기"
        isTermsAgreeModalOpen={isTermsAgreeModalOpen}
        setIsTermsAgreeModalOpen={setIsTermsAgreeModalOpen}
        onSubmitConfirmed={(data) => {
          applicationStore.privacyAgree = data.privacyCollect
          applicationStore.privacyProvideAgree = data.privacyProvide
          applicationStore.marketingAgree = data.marketingAgree
          navigate('/apply/og-application')
        }}
      />
      <div className="relative bgGridY">
        <div className="bgGridX w-screen min-h-screen flex justify-center py-[var(--grid-unit-1)]">
          <section className={classNames("pt-24 pb-16 flex flex-col justify-center items-center w-[var(--grid-unit-22)] bg-[#4545C9]", contentBorderClass)}>
            <img className="w-[320px]" src={highfive} />
            <div className="mt-10 flex flex-col justify-center text-center mb-6 font-bold text-[60px] text-white">
              Welcome<br />
              OG Members!
            </div>
            <div className="text-[16px] text-white">
              * OG WL 신청 시 <span className="underline">연결한 지갑 주소로 SBT를 민팅</span>할 수 있으니, 다시 한번 지갑 주소를 확인 부탁드립니다.
            </div>
            <button
              className="relative mt-20 px-32 py-2 rounded-[40px] text-[#4545C9] text-[24px] font-bold bg-white flex justify-center items-center"
              onClick={handleInputCodeButtonClick}
            >
              OG WL 코드 입력하기
            </button>
            <ErrorMessage message={inputCodeButtonError} />
          </section>
        </div>
      </div>
    </>
  )
}

function NotSupportedMessage() {
  return (
    <div className="relative h-[calc(100vh-80px)] overflow-hidden">
      <img className="object-cover w-full" src="/assets/1egdao-artwork-mobile-frame001.png" />
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-[rgba(255,255,255,0.8)] text-[32px] font-[700]">
        해당 페이지는<br/>
        모바일 환경에서 지원하지 않습니다.<br/>
        PC 환경에서 접속해주시길 바랍니다.<br/>
      </div>
    </div>
  )
}

export default observer(ApplyForOg)
