import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export const contentBorderClass = 'relative before:absolute before:inset-0 before:block before:border before:border-solid before:border-color-content-border-color'
export const contentBorderExcludeBottomClass = 'relative before:absolute before:inset-0 before:block before:border-t before:border-l before:border-r before:border-solid before:border-color-content-border-color'

export function LineBreak(height = 0) {
  return <br className={`content-empty block mt-[${height}px]`} />
}

export function WebTitleLineBreak() {
  return LineBreak(10)
}

export function WebDescriptionLineBreak() {
  return LineBreak(10)
}

export function MobileTitleLineBreak() {
  return LineBreak()
}

export function MobileDescriptionLineBreak() {
  return LineBreak(5)
}

// https://greensock.com/docs/v3/HelperFunctions
export function getScrollLookup(targets, position = undefined, containerAnimation = undefined) {
  let triggers = gsap.utils.toArray(targets).map(el => ScrollTrigger.create({
      // @ts-ignore
      trigger: el,
      start: position || "top top",
      refreshPriority: -10,
      containerAnimation: containerAnimation
    })),
    // @ts-ignore
    st = containerAnimation && containerAnimation.scrollTrigger;
  return target => {
    let t = gsap.utils.toArray(target)[0],
      i = triggers.length;
    while (i-- && triggers[i].trigger !== t) {};
    if (i < 0) {
      return console.warn("target not found", target);
    }
    // @ts-ignore
    return containerAnimation ? st.start + (triggers[i].start / containerAnimation.duration()) * (st.end - st.start) : triggers[i].start;
  };
}
