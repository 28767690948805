import classNames from 'classnames'

export default function ApplicationNavigator({ curSectionNum }) {
  curSectionNum = Number(curSectionNum)

  const matchedStyle = 'bg-[#4545C9] text-white'
  const notMatchedStyle = 'bg-[#F1F1F1] text-[rgba(0, 0, 0, 0.5)]'
  const navLabelCommon = 'h-full flex justify-start items-center text-[16px] xl:text-[24px] pl-[16px] font-bold border-r border-[#CCCCEC]'

  return (
    <div className="w-full h-full overflow-hidden grid grid-cols-3">
      <div className={classNames("col-start-1", {
        [matchedStyle]: curSectionNum === 1,
        [notMatchedStyle]: curSectionNum !== 1,
      })}>
        <div className={classNames(navLabelCommon)}>
          ① About you
        </div>
      </div>
      <div className={classNames("col-start-2", {
        [matchedStyle]: curSectionNum === 2,
        [notMatchedStyle]: curSectionNum !== 2,
      })}>
        <div className={classNames(navLabelCommon)}>
          ② Let's Check
        </div>
      </div>
      <div className={classNames("col-start-3", {
        [matchedStyle]: curSectionNum === 3,
        [notMatchedStyle]: curSectionNum !== 3,
      })}>
        <div className={classNames(navLabelCommon)}>
          ③ More about you
        </div>
      </div>
    </div>
  )
}
