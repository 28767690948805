import React, { useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import applyIcon from './apply.png'
import coffeeIcon from './coffee.png'
import postIcon from './post.png'
import CheckTermsAgreeModal from './modals/CheckTermsAgreeModal'
import CheckInvitationCodeModal from './modals/CheckInvitationCodeModal'
import { observer } from 'mobx-react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { useModal, useSIWE } from 'connectkit'
import { useMediaQuery } from 'usehooks-ts'

function Apply() {
  const isMobile = !useMediaQuery('(min-width: 768px)')
  const { applicationStore } = useRootStore()
  const navigate = useNavigate();
  const [applicationCount, setApplicationCount] = useState(0)
  const [approvedCount, setApprovedCount] = useState(0)
  const [isCheckInvitationCodeModalOpen, setIsCheckInvitationCodeModalOpen] = useState(false)
  const [isTermsAgreeModalOpen, setIsTermsAgreeModalOpen] = useState(false)
  const [invitationButtonClicked, setInvitationButtonClicked] = useState(false)
  const { open, setOpen } = useModal()  // wallet login modal
  const { signedIn } = useSIWE()

  useEffect(() => {
    fetchApplicationCount().then()
    fetchApprovedCount().then()
  }, [])

  useEffect(() => {
    if (invitationButtonClicked && signedIn) {
      setIsCheckInvitationCodeModalOpen(true)
    }
  }, [signedIn])

  const fetchApplicationCount = async () => {
    const { data: { data: count } } = await axiosClient.get('/api/v1/application/count')
    setApplicationCount(count)
  }

  const fetchApprovedCount = async () => {
    const { data: { data: count }} = await axiosClient.get('/api/v1/applicant/count/approved')
    setApprovedCount(count)
  }

  const handleInvitationInputButtonClick = () => {
    if (!signedIn) {
      setInvitationButtonClicked(true)
      setOpen(true)
    } else {
      setIsCheckInvitationCodeModalOpen(true)
    }
  }

  if (isMobile) {
    return <NotSupportedMessage />
  }

  return (
    <>
      <CheckInvitationCodeModal
        isModalOpen={isCheckInvitationCodeModalOpen}
        setIsModalOpen={setIsCheckInvitationCodeModalOpen}
        onSubmitConfirmed={invitationCode => {
          // delay for modal transition
          // open new modal immediately raises scroll freeze bug
          applicationStore.invitationCode = invitationCode
          setTimeout(() => {
            setIsTermsAgreeModalOpen(true)
          }, 300)
        }}
      />
      <CheckTermsAgreeModal
        isTermsAgreeModalOpen={isTermsAgreeModalOpen}
        setIsTermsAgreeModalOpen={setIsTermsAgreeModalOpen}
        onSubmitConfirmed={(data) => {
          applicationStore.privacyAgree = data.privacyCollect
          applicationStore.privacyProvideAgree = data.privacyProvide
          applicationStore.marketingAgree = data.marketingAgree
          navigate('/apply/season-1-application')
        }}
      />
      <div className="relative bgGridY">
        <div className="bgGridX w-screen min-h-screen flex justify-center py-[var(--grid-unit-1)]">
          <section className={classNames("pt-12 pb-16 flex flex-col justify-center items-center w-[var(--grid-unit-22)] bg-white", contentBorderClass)}>
            <div className="flex flex-col justify-center text-center mb-6">
              <div className="font-semibold text-[40px]">Season 1</div>
              <div className="font-bold text-[60px]">1egDAO, Seoul</div>
            </div>
            <div className="border border-1 border-[#4545C9] rounded-[30px] w-[500px] px-12 pt-8 pb-4 flex font-bold space-x-12">
              <div className="grow flex flex-col justify-center items-center">
                <div className="text-[16px]">
                  접수된 지원서
                </div>
                <div className="leading-tight text-[80px] text-[#4545C9] ">
                  {applicationCount}
                </div>
              </div>
              <div className="grow flex flex-col justify-center items-center">
                <div className="text-[16px]">
                  승인된 지원서
                </div>
                <div className="leading-tight text-[80px] text-[#4545C9]">
                  {approvedCount}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-12 font-bold text-[24px]">
              <div>
                gm! 1egDAO의 Selection Process는 3단계로 진행됩니다.
              </div>
              <div className="relative flex flex-row justify-center mt-12 space-x-12">
                <div className="w-[214px] h-[214px] border-[3px] border-[#4545C9] rounded-full flex flex-col items-center justify-center bg-white">
                  <div className="text-[24px] text-[#4545C9]">Step 1</div>
                  <div className="w-[100px] h-[1px] bg-[#4545C9] my-[10px]" />
                  <div className="text-[20px] font-[700]">Invitation</div>
                  <div className="text-[12px] font-[700]">초대코드 입력</div>
                  <img className="w-[50px] h-[50px]" src={postIcon} />
                </div>
                <div className="relative w-[214px] h-[214px] border-[3px] border-[#4545C9] rounded-full flex flex-col items-center justify-center before:absolute before:-left-[51px] before:w-[51px] before:h-[3px] before:bg-[#4545C9]">
                  <div className="text-[24px] text-[#4545C9]">Step 2</div>
                  <div className="w-[100px] h-[1px] bg-[#4545C9] my-[10px]" />
                  <div className="text-[20px] font-[700]">Application</div>
                  <div className="text-[12px] font-[700]">지원서 작성</div>
                  <img className="w-[50px] h-[50px]" src={applyIcon} />
                </div>
                <div className="relative w-[214px] h-[214px] border-[3px] border-[#4545C9] rounded-full flex flex-col items-center justify-center before:absolute before:-left-[51px] before:w-[51px] before:h-[3px] before:bg-[#4545C9]">
                  <div className="text-[24px] text-[#4545C9]">Step 3</div>
                  <div className="w-[100px] h-[1px] bg-[#4545C9] my-[10px]" />
                  <div className="text-[20px] font-[700]">Coffee-Chat</div>
                  <div className="text-[12px] font-[700]">온라인 인터뷰</div>
                  <img className="w-[50px] h-[50px]" src={coffeeIcon} />
                </div>
              </div>
              <div className="mt-[35px] font-[700] text-[16px]">
                * 추후 최종 합격자에 한해, <span className="underline">지원서 작성 시 연결한 지갑 주소로 SBT를 민팅</span>할 수 있으니, 다시 한번 지갑 주소를 확인 부탁드립니다.
              </div>
            </div>
            <button
              className="relative px-20 py-4 rounded-[40px] text-white text-[24px] font-bold bg-[#4545C9] flex justify-center items-center"
              onClick={handleInvitationInputButtonClick}
            >
              초대코드 입력하기
            </button>
          </section>
        </div>
      </div>
    </>
  )
}

function NotSupportedMessage() {
  return (
    <div className="relative h-[calc(100vh-80px)] overflow-hidden">
      <img className="object-cover w-full" src="/assets/1egdao-artwork-mobile-frame001.png" />
      <div className="absolute inset-0 flex flex-col justify-center items-center bg-[rgba(255,255,255,0.8)] text-[32px] font-[700]">
        해당 페이지는<br/>
        모바일 환경에서 지원하지 않습니다.<br/>
        PC 환경에서 접속해주시길 바랍니다.<br/>
      </div>
    </div>
  )
}

export default observer(Apply)
