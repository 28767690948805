import { mainnet, goerli } from 'wagmi/chains'
import { Chain } from '@wagmi/chains'
import _ from 'lodash'

type AppEnv = 'local' | 'development' | 'production'

export const appEnv: AppEnv = process.env.REACT_APP_APP_ENV ? process.env.REACT_APP_APP_ENV as AppEnv : 'local'

interface Config {
  app: {
    chains: Chain[]
    apiEndpoint: string
    apiTimeoutMillis: number
  }
  twitter: string
  medium: string
  effects: {
    fadeIn: {
      start: string
      duration: number
      ease: string
      pastStartOfThePrevious: string
    }
  }
  alchemy: {
    appName: string
    apiKey: string
  }
}

const defaultConfig = {
  app: {
    apiTimeoutMillis: 5000,
  },
  twitter: 'https://twitter.com/1egdao',
  medium: 'https://1egdao.medium.com',
  effects: {
    fadeIn: {
      start: 'top center',
      duration: 1.2,
      ease: 'power2.out',  // ref: https://greensock.com/docs/v3/Eases
      pastStartOfThePrevious: '<0.2',
    },
  },
  alchemy: {
    appName: '1eg-selective',
    apiKey: 'pDLEvy5efOZRj_GMeDY59rJ6tipdFDVN',
  }
}

const configs = {
  local: {
    app: {
      chains: [goerli],
      apiEndpoint: 'http://localhost:8080',
    },
  },
  development: {
    app: {
      chains: [goerli],
      apiEndpoint: 'https://api-dev.1egdao.io',
    },
  },
  production: {
    app: {
      chains: [mainnet],
      apiEndpoint: 'https://api.1egdao.io',
    },
  },
}

const config: Config = _.merge(defaultConfig, configs[appEnv])

export const fadeInOptions = {
  opacity: 0,
  ease: 'power2.out',
  duration: config.effects.fadeIn.duration,
}

export default config
