import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ApplicationNavigator from '@src/pages/apply/season-1-application/ApplicationNavigator'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import React, { useEffect, useState } from 'react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { ErrorMessage } from '@src/components/ErrorMessage/ErrorMessage'
import axiosClient from '@src/client/axiosClient'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'


const questionText = 'font-bold text-[24px]'

const ApplicationSection3 = () => {
  const { applicationStore } = useRootStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm()
  const [isLoading, setIsLoading] = useState(false)

  const currentSection = searchParams.get('section') || 1

  useEffect(() => {
    setValue('impression', applicationStore.ogQ1Impression)
    setValue('ability', applicationStore.ogQ2Ability)
    setValue('howToConnect', applicationStore.ogQ3HowToConnect)
    setValue('vision', applicationStore.ogQ4Vision)
    setValue('anything', applicationStore.ogQ5Anything)
    setValue('portfolio', applicationStore.ogQ6Portfolio)
  }, [])

  const onSubmit = async () => {
    saveForm()

    try {
      setIsLoading(true)

      const form = applicationStore.getRequestOgForm()
      await axiosClient.post(`/api/v1/application/og-register`, form)
      navigate({
        pathname: '/apply/og-application',
        search: '?section=success',
      })
      window.scrollTo(0, 0)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePreviousClick = () => {
    saveForm()

    navigate({
      pathname: '/apply/og-application',
      search: '?section=2',
    })
    window.scrollTo(0, 0)
  }

  const saveForm = () => {
    const data = getValues()
    applicationStore.ogQ1Impression = data.impression
    applicationStore.ogQ2Ability = data.ability
    applicationStore.ogQ3HowToConnect = data.howToConnect
    applicationStore.ogQ4Vision = data.vision
    applicationStore.ogQ5Anything = data.anything
    applicationStore.ogQ6Portfolio = data.portfolio
  }

  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <nav className="h-[var(--grid-unit-1)] w-[var(--grid-unit-15)]">
          <ApplicationNavigator curSectionNum={currentSection} />
        </nav>
        <section className={classNames("w-full bg-white", contentBorderClass)}>
          <form className="relative space-y-12 p-[80px] mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center"
                onSubmit={handleSubmit(onSubmit, console.warn)}>

            <div className="w-full space-y-4">
              <label htmlFor="impression" className={classNames("block mb-[30px]", questionText)}>
                1. 본인에 대한 소개와, 1egDAO 활동을 하며 느꼈던 점들에 대해 자유롭게 적어주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('impression', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'introduce' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="ability" className={classNames("block mb-[30px]", questionText)}>
                2. 가장 인상적인 웹3 경험을 자신의 역량과 장점 중심으로 적어주세요.<br />
                만약 경험이 없다면, 향후 하고 싶은 웹3 활동에 대해 적어주세요.
                <p className="mt-4 font-medium text-[24px]">
                  (단, (2) Let’s Check 단계에서 선택한 웹3 역량을 포함해주세요.)
                </p>
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('ability', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'ability' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="howToConnect" className={classNames("block mb-[30px]", questionText)}>
                3. (2) Let’s Check 단계에서 선택한 웹2의 영역과 웹3를 어떻게 연결하고 싶은지, <br />
                그 이유와 함께 간단히 설명해주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('howToConnect', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'howToConnect' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="vision" className={classNames("block mb-[30px]", questionText)}>
                4. 앞으로 1egDAO가 어떻게 나아갔으면 좋겠는지, 그 과정에서 어떻게 함께하고 싶으신지 적어주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('vision', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'howToConnect' }} />
            </div>

            {/* 아래 하고싶은말 질문은 OG 로 부터 받지 않도록 변경됨 */}
            <div className="w-full space-y-4 hidden">
              <label htmlFor="anything" className={classNames("block mb-[30px]", questionText)}>
                -. 마지막으로 하고 싶은 말을 자유롭게 적어주세요. (선택)
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('anything')}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'anything' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="portfolio" className={classNames("block mb-[30px]", questionText)}>
                5. 포트폴리오 링크 첨부하기 (선택)
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('portfolio')}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'portfolio' }} />
            </div>

            <div className="relative grid grid-cols-5">
              <div className="col-start-1 col-span-1 flex justify-start items-center cursor-pointer text-[#808080]" onClick={handlePreviousClick}>
                <ChevronLeftIcon className="w-[42px] h-[42px]" />
                <span>이전</span>
              </div>
              <button
                type="submit"
                className={classNames("bg-[#4545C9] text-white cursor-pointer select-none self-center w-[600px] h-[80px] text-[30px] text-white rounded-[40px] font-bold flex justify-center items-center")}
              >
                {isLoading ? <LoadingIcon /> : '제출하기'}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}

function LoadingIcon() {
  return (
    <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

export default observer(ApplicationSection3)
