import { ConnectKitButton } from "connectkit"
import { observer } from 'mobx-react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { shorten } from '@src/utils/Web3AddressUtil'
import classNames from 'classnames'

function Web3ConnectButton() {
  const { userStore } = useRootStore()

  return (
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName }) => {
        return (
          <button className={classNames("h-[45px] rounded-[30px] text-[20px] font-semibold transition-colors duration-300 bg-[#4545C9] text-white flex justify-center items-center", {
            'w-[240px]': !isConnected,
            'w-[150px]': isConnected,
          })}
                  onClick={show}>
            {isConnected
              ? (userStore.isSignedIn ? shorten(address) : 'SIGN IN')
              : "CONNECT WALLET"}
          </button>
        );
      }}
    </ConnectKitButton.Custom>
  )
}

export default observer(Web3ConnectButton)
