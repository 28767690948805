import { useLayoutEffect } from "react"
import cx from 'classnames'
import globalMap from './global-map.png'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {contentBorderClass} from "@src/Styles"
import config, {fadeInOptions} from "@src/config"
import {useMediaQuery} from "usehooks-ts"
import selectionProcess from './selection-process.png'
import selectionProcessMobile from './selection-process-mobile.png'
import './TrustProtocolPage.css'
import mapAndSeoul from './map-and-seoul.png'
import otherCities from './other-cities.png'
import whiteLogo from './white-logo.png'
import {useTranslation} from "react-i18next";

let once

export default function TrustProtocolPage() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  useLayoutEffect(() => {
    if (once) {
      return;
    }
    once = true;

    gsap.from('.trustProtocol .trustProtocolTitleSection', {
      scrollTrigger: {
        trigger: '.trustProtocol .trustProtocolTitle',
        start: config.effects.fadeIn.start,
      },
      ...fadeInOptions,
    })

    ScrollTrigger.create({
      trigger: '.trustProtocol .trustProtocolSection',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.trustProtocol .trustProtocolDescriptionTitle', fadeInOptions)
        .from('.trustProtocol .trustProtocolDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.trustProtocol .trustProtocolDescriptionFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

    if (isMobile) {
      ScrollTrigger.create({
        trigger: '.trustProtocol .mProofOfTrustSection',
        start: config.effects.fadeIn.start,
        animation: gsap.timeline()
          .from('.trustProtocol .mProofOfTrustSectionFigure', fadeInOptions)
          .from('.trustProtocol .mProofOfTrustSectionTitle', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
          .from('.trustProtocol .mProofOfTrustSectionDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
      })

      ScrollTrigger.create({
        trigger: '.trustProtocol .mWeb2Web3BridgeSection',
        start: config.effects.fadeIn.start,
        animation: gsap.timeline()
          .from('.trustProtocol .mWeb2Web3BridgeFigure', fadeInOptions)
          .from('.trustProtocol .mWeb2Web3BridgeTitle', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
          .from('.trustProtocol .mWeb2Web3BridgeDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
      })

      ScrollTrigger.create({
        trigger: '.trustProtocol .mGlobalLaunchpadSection',
        start: config.effects.fadeIn.start,
        animation: gsap.timeline()
          .from('.trustProtocol .mGlobalLaunchpadFigure', fadeInOptions)
          .from('.trustProtocol .mGlobalLaunchpadTitle', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
          .from('.trustProtocol .mGlobalLaunchpadDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
      })
    } else {
      const tl = gsap.timeline({
        defaults: {
          duration: 10,
        },
      })
        .to(['.proofOfTrustTitle', '.proofOfTrustContent'], {
          opacity: 0,
        }, '+=20')
        .to(['.daoCircleMain'], {
          scale: 0.4,
        }, '>')
        .to(['.daoCircleShadow1'], {
          scale: 0.6,
        }, '<')
        .to(['.daoCircleShadow2'], {
          scale: 0.8,
        }, '<')
        .to(['.daoCircleText'], {
          scale: 0.6,
        }, '<')
        .from(['.daoCircleOuterText'], {
          opacity: 0,
        }, '<')
        .from(['.web2Web3BridgeTitle', '.web2Web3BridgeContent'], {
          opacity: 0,
        }, '>')
        .to(['.daoCircle'], {
          scale: 0,
        }, '+=10')
        .to(['.web2Web3BridgeTitle', '.web2Web3BridgeContent'], {
          opacity: 0,
        }, '<')
        .from(['.mapAndSeoul', '.globalLaunchPadTitle', '.globalLaunchPadContent'], {
          opacity: 0,
        }, '+=10')
        .from(['.otherCities'], {
          opacity: 0,
        }, '+=10')
        .from(['.pinnedDelayer'], {
          duration: 0,  // it's simple delay trick not visible
        }, '+=20')

      // it binds with header navigation
      // @ts-ignore
      window.pinTl = tl

      ScrollTrigger.create({
        trigger: '.trustProtocol .proofOfTrustSection',
        start: 'center center',
        pin: '.appBody',
        end: '+=4000 center',
        scrub: true,
        fastScrollEnd: true,
        animation: tl,
      })
    }
  }, [])

  return (
    <div className="trustProtocol relative">
      <TrustProtocolTitleSection />
      <TrustProtocolSection />
      <TrustProtocolPinnedSection />
    </div>
  )
}

function TrustProtocolTitleSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section id="trust-protocol" className="bgGridY bg-[#4545C9]">
      <div className="bgGridX">
        {isMobile ? (
          <div className="trustProtocolTitleSection grid grid-cols-12 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)]">
            <div className="col-start-2 col-end-3 row-start-3 row-end-4 bg-white" />
            <div className="col-start-11 col-end-12 row-start-16 row-end-17 bg-white" />
            <h1 className="trustProtocolTitle col-start-2 col-end-12 row-start-4 row-end-16 flex justify-start items-center leading-relaxed font-semibold text-white" style={{ fontSize: 'clamp(2rem, 8vw, 6rem)' }}>
              Trust Protocol<br />
              for Human Capital
            </h1>
          </div>
        ) : (
          <div className="trustProtocolTitleSection grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <h1 className="trustProtocolTitle col-start-3 col-end-23 row-start-4 row-end-10 flex justify-start items-center leading-relaxed font-semibold text-white"
                style={{ fontSize: 'var(--section-title-font-size)' }}>
              Trust Protocol<br />
              for Human Capital
            </h1>
            <div className="col-start-22 col-end-23 row-start-10 row-end-11 bg-white" />
          </div>
        )}
      </div>
    </section>
  )
}

function TrustProtocolSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="relative bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="trustProtocolSection grid grid-cols-10 grid-rows-17 h-[var(--grid-unit-18)] w-full px-[var(--grid-unit-1)] pt-[var(--grid-unit-1)]">
            <h1 className="trustProtocolDescriptionTitle col-start-1 col-end-7 row-start-1 row-end-3 flex justify-center items-center text-[#4545C9] font-semibold"
                style={{ fontSize: 'clamp(1rem, 5.208vw, 2.5rem)' }}>
              Trust Protocol
            </h1>
            <div className={cx("trustProtocolDescription relative col-start-1 col-end-11 row-start-3 row-end-10 bg-white", contentBorderClass)}>
              <div className="w-full h-full flex flex-col justify-start pt-[4.167vw] px-[var(--grid-unit-1)]">
                <div className="titleParagraph">
                  {t('trust.protocol.desc1')}<br />
                  {t('trust.protocol.desc2')}<br />
                  {t('trust.protocol.desc3')}
                </div>
                <div className="descriptionParagraph">
                  {t('trust.protocol.desc4')}<br />
                  {t('trust.protocol.desc5')}<br />
                  {t('trust.protocol.desc6')}<br />
                  {t('trust.protocol.desc7')}
                </div>
              </div>
            </div>
            <figure className="trustProtocolDescriptionFigure col-start-1 col-end-11 row-start-11 row-end-18 flex justify-center items-center">
              <img className="w-full h-full object-fill" src={selectionProcessMobile} alt="selection process" />
            </figure>
          </div>
        ) : (
          <div className="trustProtocolSection grid grid-cols-22 grid-rows-10 h-[var(--grid-unit-12)] w-full p-[var(--grid-unit-1)]">
            <h1 className="trustProtocolDescriptionTitle col-start-2 col-end-8 row-start-2 row-end-4 flex justify-center items-center text-[#4545C9] font-semibold" style={{ fontSize: 'clamp(1.5rem, 3.125vw, 3.75rem)' }}>
              Trust Protocol
            </h1>
            <div className={cx("trustProtocolDescription relative bg-white col-start-2 col-end-10 row-start-4 row-end-11", contentBorderClass)}>
              <div className="w-full h-full flex flex-col justify-start pt-[2.083vw] pl-[var(--grid-unit-1)] pr-[3vw]">
                <div className="font-bold leading-[150%]" style={{ fontSize: 'clamp(1.1rem, 1.667vw, 2rem)' }}>
                  {t('trust.protocol.desc1')}<br />
                  {t('trust.protocol.desc2')}<br />
                  {t('trust.protocol.desc3')}
                </div>
                <div className="font-semibold mt-[1.2vw] leading-[150%]" style={{ fontSize: 'clamp(0.9rem, 1.25vw, 1.5rem)' }}>
                  {t('trust.protocol.desc4')}<br />
                  {t('trust.protocol.desc5')}<br />
                  {t('trust.protocol.desc6')}<br />
                  {t('trust.protocol.desc7')}
                </div>
              </div>
            </div>
            <div className="col-start-1 col-end-2 row-start-1 row-end-2 bg-[#4545C9]" />
            <figure className="trustProtocolDescriptionFigure col-start-11 col-end-22 row-start-3 row-end-11 flex justify-center items-center">
              <img className="object-contain" src={selectionProcess} alt="selection process" />
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}

function TrustProtocolPinnedSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  if (isMobile) {
    return (
      <>
        <section className="mProofOfTrustSection relative bgGridY">
          <div className="bgGridX">
            <div className="grid grid-cols-10 grid-rows-16 h-[var(--grid-unit-18)] w-full p-[var(--grid-unit-1)]">
              <div className="col-start-10 col-end-11 row-start-9 row-end-10 bg-[#E1E1FD]" />
              <figure className="mProofOfTrustSectionFigure relative col-start-1 col-end-11 row-start-1 row-end-9 px-[var(--grid-unit-1)] flex justify-center items-center">
                <div className="relative w-full h-full flex justify-center items-center bg-main-blue rounded-full">
                  <div className="flex justify-center items-center text-white font-semibold"
                       style={{ fontSize: 'clamp(2rem, 10.417vw, 5rem)' }}>
                    1egDAO
                  </div>
                </div>
              </figure>
              <div className={cx("relative col-start-1 col-end-10 row-start-10 row-end-17 flex flex-col pt-4 pl-6 bg-white overflow-y-hidden", contentBorderClass)}>
                <h2 className="mProofOfTrustSectionTitle mb-2 xs:mb-8 trustTitle">
                  Proof of Trust
                </h2>
                <div className="mProofOfTrustSectionDescription trustDescription">
                  {t('trust.proofOfTrust.desc1')}<br/>
                  {t('trust.proofOfTrust.desc2')}
                </div>
                <div className="mProofOfTrustSectionDescription trustDescription">
                  {t('trust.proofOfTrust.desc3')}<br/>
                  {t('trust.proofOfTrust.desc4')}<br/>
                  {t('trust.proofOfTrust.desc5')}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mWeb2Web3BridgeSection relative bgGridY">
          <div className="bgGridX">
            <div className="grid grid-cols-10 grid-rows-16 h-[var(--grid-unit-18)] w-full p-[var(--grid-unit-1)]">
              <div className="col-start-10 col-end-11 row-start-9 row-end-10 bg-[#E1E1FD]" />
              <figure className="mWeb2Web3BridgeFigure relative col-start-1 col-end-11 row-start-1 row-end-9 px-[var(--grid-unit-1)] flex justify-center items-center">
                <div className="relative w-full h-full flex justify-center items-center">
                  <div className="absolute inset-0 bg-[#DADEFB] rounded-full" />
                  <div className="absolute inset-0 scale-[0.8] bg-[#B9B9F5] rounded-full" />
                  <div className="absolute inset-0 scale-[0.6] bg-[#9D9DEB] rounded-full" />
                  <div className="absolute inset-0 scale-[0.4] bg-[#4545C9] rounded-full" />
                  <div className="flex justify-center items-center text-white font-semibold z-10"
                       style={{ fontSize: 'clamp(1rem, 3.906vw, 1.875rem)' }}>
                    Web2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1egDAO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Web3
                  </div>
                </div>
              </figure>
              <div className={cx("relative col-start-1 col-end-10 row-start-10 row-end-17 flex flex-col pt-4 pl-6 bg-white", contentBorderClass)}>
                <h2 className="mWeb2Web3BridgeTitle mb-2 xs:mb-8 trustTitle">
                  Web2 - Web3 Bridge
                </h2>
                <div className="mWeb2Web3BridgeDescription trustDescription">
                  {t('trust.bridge.desc1')}<br />
                  {t('trust.bridge.desc2')}<br />
                  {t('trust.bridge.desc3')}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mGlobalLaunchpadSection relative bgGridY">
          <div className="bgGridX">
            <div className="grid grid-cols-12 grid-rows-18 h-[var(--grid-unit-18)] w-full">
              <div className="col-start-11 col-end-12 row-start-9 row-end-10 bg-[#E1E1FD]" />
              <figure className="mGlobalLaunchpadFigure relative col-start-1 col-end-10 row-start-2 row-end-10">
                <img className="max-h-full object-contain" src={globalMap} alt="global map" />
              </figure>
              <div className={cx("relative col-start-2 col-end-11 row-start-10 row-end-17 flex flex-col pt-4 pl-6 bg-white", contentBorderClass)}>
                <h2 className="mGlobalLaunchpadTitle mb-2 xs:mb-8 trustTitle">
                  Global Launchpad
                </h2>
                <div className="mGlobalLaunchpadDescription trustDescription">
                  {t('trust.launchpad.desc1')}<br />
                  {t('trust.launchpad.desc2')}<br />
                  {t('trust.launchpad.desc3')}<br />
                  {t('trust.launchpad.desc4')}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      <section className="proofOfTrustSection relative bgGridY">
        <div className="bgGridX">
          <div className="grid grid-cols-24 grid-rows-12 h-[var(--grid-unit-12)]">
            <figure className="relative col-start-1 col-end-12 row-start-1 row-end-12 p-[var(--grid-unit-1)] flex justify-center items-center">
              <div className="daoCircle relative flex justify-center items-center" style={{ width: 'clamp(1rem, 33.333vw, 40rem', height: 'clamp(1rem, 33.333vw, 40rem' }}>
                <div className="daoCircleShadow3 absolute inset-0 bg-[#DADEFB] rounded-full" />
                <div className="daoCircleShadow2 absolute inset-0 bg-[#B9B9F5] rounded-full" />
                <div className="daoCircleShadow1 absolute inset-0 bg-[#9D9DEB] rounded-full" />
                <div className="daoCircleMain absolute inset-0 bg-[#4545C9] rounded-full" />
                <div className="daoCircleText absolute inset-0 flex justify-center items-center text-white">
                  <img className="w-[50%]" src={whiteLogo} alt="white 1egdao logo" />
                </div>
                <div className="daoCircleOuterText absolute inset-0 flex justify-between items-center text-white px-[2vw]"
                     style={{ fontSize: 'clamp(0.6rem, 2.5vw, 3rem)', fontWeight: '600' }}>
                  <span>Web2</span>
                  <span>Web3</span>
                </div>
              </div>
            </figure>
            <figure className="globalMap relative col-start-1 col-end-12 row-start-1 row-end-13 flex justify-start items-start">
              <img className="mapAndSeoul absolute inset-0 w-full h-full" src={mapAndSeoul} alt="seoul on global map" />
              <img className="otherCities absolute inset-0 w-full h-full" src={otherCities} alt="other cities on global map" />
            </figure>
            <div className={cx("relative col-start-14 col-end-22 row-start-3 row-end-5 font-semibold text-[#4545C9] bg-white overflow-y-hidden", contentBorderClass)} style={{ fontSize: 'clamp(1rem, 3.125vw, 3.75rem)' }}>
              <h2 className="proofOfTrustTitle absolute inset-0 flex justify-center items-center">
                Proof of Trust
              </h2>
              <h2 className="web2Web3BridgeTitle absolute inset-0 flex justify-center items-center">
                Web2-Web3 Bridge
              </h2>
              <h2 className="globalLaunchPadTitle absolute inset-0 flex justify-center items-center">
                Global Launchpad
              </h2>
            </div>
            <div className={cx("relative col-start-12 col-end-22 row-start-5 row-end-12 font-bold bg-white", contentBorderClass)} style={{ fontSize: 'clamp(1rem, 1.667vw, 2rem)' }}>
              <article className="proofOfTrustContent absolute top-[2.604vw] left-[var(--grid-unit-1)] right-[var(--grid-unit-1)] bottom-0 flex flex-col justify-start">
                <div className="descriptionWithoutTitleParagraph">
                  {t('trust.proofOfTrust.desc1')}<br/>
                  {t('trust.proofOfTrust.desc2')}
                </div>
                <div className="descriptionWithoutTitleParagraph">
                  {t('trust.proofOfTrust.desc3')}<br/>
                  {t('trust.proofOfTrust.desc4')}<br/>
                  {t('trust.proofOfTrust.desc5')}
                </div>
              </article>
              <article className="web2Web3BridgeContent absolute top-[2.604vw] left-[var(--grid-unit-1)] right-[var(--grid-unit-1)] bottom-0 flex flex-col justify-start">
                <div className="descriptionWithoutTitleParagraph">
                  {t('trust.bridge.desc1')}<br />
                  {t('trust.bridge.desc2')}<br />
                  {t('trust.bridge.desc3')}
                </div>
              </article>
              <article className="globalLaunchPadContent absolute top-[2.604vw] left-[var(--grid-unit-1)] right-[var(--grid-unit-1)] bottom-0 flex flex-col justify-start">
                <div className="descriptionWithoutTitleParagraph">
                  {t('trust.launchpad.desc1')}<br />
                  {t('trust.launchpad.desc2')}<br />
                  {t('trust.launchpad.desc3')}<br />
                  {t('trust.launchpad.desc4')}
                </div>
              </article>
            </div>
            <div className="col-start-22 col-end-23 row-start-2 row-end-3 bg-[#DADEFB]" />
            <div className="invisible pinnedDelayer" />
          </div>
        </div>
      </section>
    </>
  )
}
