import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import './i18n/i18n'
import { RootStoreProvider } from '@src/stores/RootStoreProvider'
import { configure } from "mobx"
import App from '@src/App'

// https://mobx.js.org/configuration.html#linting-options
configure({
  enforceActions: "never",
})

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <React.StrictMode>
    <RootStoreProvider>
      <App />
    </RootStoreProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
