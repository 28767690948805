import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message'
import classNames from 'classnames'

interface ErrorMessageProps {
  message?: string
  additionalClassName?: string
  useFormProps?: {
    name: string,
    errors: any,
  }
}

export function ErrorMessage({ additionalClassName, message, useFormProps }: ErrorMessageProps) {
  if (useFormProps) {
    return (
      <HookFormErrorMessage
        name={useFormProps.name}
        errors={useFormProps.errors}
        render={({ message }) => (
          <p className={classNames("flex justify-center text-[20px] text-[#FF4D4D]", additionalClassName)}>
            {message}
          </p>
        )}
      />
    )
  }

  return (
    <p className={classNames("flex justify-center text-[20px] text-[#FF4D4D]", additionalClassName)}>
      {message}
    </p>
  )
}
