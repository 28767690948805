import { useForm } from 'react-hook-form'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import sleep from '@src/utils/sleep'

const INVISIBLE_CHAR = `‎`
const ANSWER = '14561732'

export default function CheckQuizModal({ isModalOpen, setIsModalOpen, onSubmitConfirmed }) {
  const { register, handleSubmit, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(INVISIBLE_CHAR)

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      setErrorMessage(INVISIBLE_CHAR)

      await sleep(1500)

      if (data.invitationCode !== ANSWER) {
        setErrorMessage('사용 불가능한 코드입니다. 다시 입력해주세요.')
      } else {
        onClose()
        onSubmitConfirmed(data.invitationCode)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    setIsModalOpen(false)
    reset()
  }

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="reative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all w-[520px]">
                <div className="relative flex justify-center border-b border-[#CCCCEC]">
                  <Dialog.Title as="h3" className="text-[24px] text-[#4545C9] font-bold flex items-center my-4 mx-6">
                    OG WL 코드 입력
                    <div className="absolute right-4 cursor-pointer" onClick={onClose}>
                      <XMarkIcon className="h-6 w-6 text-[#4545C9]" strokeWidth={3} />
                    </div>
                  </Dialog.Title>
                </div>
                <div className="p-[50px]">
                  <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                    <div className="text-[20px] font-bold mx-auto">
                      1egDAO의 생일이 기록된 이더리움 블록 번호는?
                    </div>
                    <div className="mt-8 mb-4">
                      <input
                        type="text"
                        className="block text-center text-2xl w-full h-full py-2 mb-1 border border-[#CCCCEC] focus:border-indigo-500 focus:ring-indigo-500"
                        autoComplete="off"
                        placeholder="Enter your code"
                        {...register('invitationCode')}
                      />
                      <div className="flex justify-center text-[18px] text-[#FF4D4D]">
                        {errorMessage}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full h-[55px] bg-[#4545C9] rounded-[30px] text-white text-[20px] font-bold transition-colors duration-300 hover:bg-[#4545C9] hover:text-white flex justify-center items-center"
                    >
                      {isLoading ? <LoadingIcon /> : '신청 시작하기'}
                    </button>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function LoadingIcon() {
  return (
    <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}
