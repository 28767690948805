import { observer } from 'mobx-react'
import { Link, useSearchParams } from 'react-router-dom'
import ApplicationSection1 from './ApplicationSection1'
import ApplicationSection2 from './ApplicationSection2'
import ApplicationSection3 from './ApplicationSection3'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import completed from '../completed.png'

const OgApplication = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSection = searchParams.get('section')
  switch(currentSection) {
    case 'success':
      return <SubmitSuccess />
    case '3':
      return <ApplicationSection3 />
    case '2':
      return <ApplicationSection2 />
    case '1':
    default:
      return <ApplicationSection1 />
  }
}

function SubmitSuccess() {
  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <section className={classNames("text-white bg-[#4545C9] relative p-[80px]", contentBorderClass)}>
          <div className="relative mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center items-center">
            <img className="w-[200px] mb-10" src={completed} />
            <h3 className="text-center font-bold mb-16">
              <div className="text-[80px]">OG WL</div>
              <div className="text-[40px]">신청서가 성공적으로 제출되었습니다.</div>
            </h3>
            <div className="mb-20 text-center flex items-center justify-center text-[25px] font-bold">
              추후 민팅 일정은 디스코드를 통해 공지하겠습니다.<br />
              지금까지 함께해 주셔서 감사하고, 앞으로도 우리 함께해요!🫶
            </div>
            <Link to="/">
              <button
                type="submit"
                className="relative px-40 py-2 rounded-[40px] text-[#4545C9] text-[24px] font-bold bg-white flex justify-center items-center"
              >
                메인 페이지로 가기
              </button>
            </Link>
          </div>
        </section>
      </div>
    </div>
  )
}

export default observer(OgApplication)
