import { ApplicationStore } from '@src/stores/ApplicationStore'
import { UserStore } from '@src/stores/UserStore'

export class RootStore {
  applicationStore: ApplicationStore
  userStore: UserStore

  constructor() {
    this.applicationStore = new ApplicationStore(this)
    this.userStore = new UserStore(this)
  }
}
