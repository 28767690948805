import { createClient } from 'wagmi'
import config from '@src/config'
import { getDefaultClient } from 'connectkit'

const wagmiClient = createClient(
  getDefaultClient({
    appName: config.alchemy.appName,
    alchemyId: config.alchemy.apiKey,
    chains: config.app.chains,
  })
)

export default wagmiClient
