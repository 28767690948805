import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ApplicationNavigator from '@src/pages/apply/season-1-application/ApplicationNavigator'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import React, { useEffect, useState } from 'react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Transition } from '@headlessui/react'
import { ErrorMessage } from '@src/components/ErrorMessage/ErrorMessage'
import axiosClient from '@src/client/axiosClient'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'


const questionText = 'font-bold text-[24px]'

const ApplicationSection3 = () => {
  const { applicationStore } = useRootStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm()
  const [isQ2LinkToggled, setIsQ2LinkToggled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const currentSection = searchParams.get('section') || 1

  useEffect(() => {
    setValue('introduce', applicationStore.q1Introduce)
    setValue('opinion', applicationStore.q2Opinion)
    setValue('oneSentence', applicationStore.q3OneSentence)
    setValue('reason', applicationStore.q4Reason)
    setValue('ability', applicationStore.q5Ability)
    setValue('howToConnect', applicationStore.q6HowToConnect)
    setValue('anything', applicationStore.q7Anything)
    setValue('portfolio', applicationStore.q8Portfolio)
  }, [])

  const onSubmit = async () => {
    saveForm()

    try {
      setIsLoading(true)

      const form = applicationStore.getRequestForm()
      await axiosClient.post(`/api/v1/application/register`, form)
      navigate({
        pathname: '/apply/season-1-application',
        search: '?section=success',
      })
      window.scrollTo(0, 0)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePreviousClick = () => {
    saveForm()

    navigate({
      pathname: '/apply/season-1-application',
      search: '?section=2',
    })
    window.scrollTo(0, 0)
  }

  const saveForm = () => {
    const data = getValues()
    applicationStore.q1Introduce = data.introduce
    applicationStore.q2Opinion = data.opinion
    applicationStore.q3OneSentence = data.oneSentence
    applicationStore.q4Reason = data.reason
    applicationStore.q5Ability = data.ability
    applicationStore.q6HowToConnect = data.howToConnect
    applicationStore.q7Anything = data.anything
    applicationStore.q8Portfolio = data.portfolio
  }

  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <nav className="h-[var(--grid-unit-1)] w-[var(--grid-unit-15)]">
          <ApplicationNavigator curSectionNum={currentSection} />
        </nav>
        <section className={classNames("w-full bg-white", contentBorderClass)}>
          <form className="relative space-y-12 p-[80px] mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center"
                onSubmit={handleSubmit(onSubmit, console.warn)}>

            <div className="w-full space-y-4">
              <label htmlFor="introduce" className={classNames("block mb-[30px]", questionText)}>
                1. 본인에 대한 소개와 1egDAO에 지원한 동기를 적어주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('introduce', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'introduce' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="opinion" className={classNames("block mb-[10px]", questionText)}>
                2. 아래 트윗 스레드 4개 중 1개를 읽고, 이에 대한 자신의 생각을 적어주세요.
              </label>
              <div
                className="flex justify-start items-center text-[24px] cursor-pointer"
                onClick={() => {
                  setIsQ2LinkToggled(!isQ2LinkToggled)
                }}
              >
                {isQ2LinkToggled ? (
                  <ChevronDownIcon className="inline-block mr-4 w-[20px] h-[20px]" />
                ) : (
                  <ChevronRightIcon className="inline-block mr-4 w-[20px] h-[20px]" />
                )}
                <span>
                  토글을 눌러 트윗 링크 확인하기 (제목을 누르면 해당 트윗 링크로 이동합니다.)
                </span>
              </div>
              <Transition
                show={isQ2LinkToggled}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pl-16 space-y-2 pt-4 decoration-1 underline">
                  <a
                    className="block text-[20px] text-[rgba(0,0,0,0.7)]"
                    href="https://twitter.com/roypa_eth/status/1506103963489222657?s=20&t=vWMIzK3Yh-U-lu0d9oDrpg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    100ETH로 BAYC를 사지 않고, 1egDAO 트레저리에 넣는 이유
                  </a>
                  <a
                    className="block text-[20px] text-[rgba(0,0,0,0.7)]"
                    href="https://twitter.com/roypa_eth/status/1508285829470617602?s=20&t=vWMIzK3Yh-U-lu0d9oDrpg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NFT 시장에서 우리가 ‘Short-term view(숏텀뷰)를 갖게 되는 이유
                  </a>
                  <a
                    className="block text-[20px] text-[rgba(0,0,0,0.7)]"
                    href="https://twitter.com/roypa_eth/status/1529386904994467842?s=20&t=7fbk4MHwSYXDqm_ON0I_tA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WAGMI의 이중성, 웹3다운 프로젝트란 무엇일까?
                  </a>
                  <a
                    className="block text-[20px] text-[rgba(0,0,0,0.7)]"
                    href="https://twitter.com/roypa_eth/status/1554775326525620225?s=20&t=d4hTBlXGCVraO1E0ZuouHg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    웹3는 모두 따로 놀고 있다
                  </a>
                </div>
              </Transition>
              <textarea
                rows={5}
                className="mt-6 block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('opinion', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'opinion' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="oneSentence" className={classNames("block mb-[30px]", questionText)}>
                3. 웹3를 한 문장으로 정의해주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('oneSentence', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'oneSentence' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="reason" className={classNames("block mb-[30px]", questionText)}>
                4. 웹3에 흥미를 가지게 된 계기와, 최근 웹3씬을 바라보며 들었던 생각을 적어주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('reason', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'reason' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="ability" className={classNames("block mb-[30px]", questionText)}>
                5. 가장 인상적인 웹3 경험을 자신의 역량과 장점 중심으로 적어주세요.<br />
                만약 경험이 없다면, 향후 하고 싶은 웹3 활동에 대해 적어주세요.
                <p className="mt-4 font-medium text-[24px]">
                  (단, (2) Let’s Check 단계에서 선택한 웹3 역량을 포함해주세요.)
                </p>
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('ability', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'ability' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="howToConnect" className={classNames("block mb-[30px]", questionText)}>
                6. (2) Let’s Check 단계에서 선택한 웹2의 영역과 웹3를 어떻게 연결하고 싶은지, <br />
                그 이유와 함께 간단히 설명해주세요.
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('howToConnect', { required: '답변을 입력해야 합니다' })}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'howToConnect' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="anything" className={classNames("block mb-[30px]", questionText)}>
                7. 마지막으로 하고 싶은 말을 자유롭게 적어주세요. (선택)
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('anything')}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'anything' }} />
            </div>

            <div className="w-full space-y-4">
              <label htmlFor="portfolio" className={classNames("block mb-[30px]", questionText)}>
                8. 포트폴리오 링크 첨부하기 (선택)
              </label>
              <textarea
                rows={5}
                className="block p-4 w-full rounded-md border border-[#CCCCEC] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
                {...register('portfolio')}
              />
              <ErrorMessage additionalClassName="col-start-2 col-span-full" useFormProps={{ errors: errors, name: 'portfolio' }} />
            </div>

            <div className="relative grid grid-cols-5">
              <div className="col-start-1 col-span-1 flex justify-start items-center cursor-pointer text-[#808080]" onClick={handlePreviousClick}>
                <ChevronLeftIcon className="w-[42px] h-[42px]" />
                <span>이전</span>
              </div>
              <button
                type="submit"
                className={classNames("bg-[#4545C9] text-white cursor-pointer select-none self-center w-[600px] h-[80px] text-[30px] text-white rounded-[40px] font-bold flex justify-center items-center")}
              >
                {isLoading ? <LoadingIcon /> : '제출하기'}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}

function LoadingIcon() {
  return (
    <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

export default observer(ApplicationSection3)
