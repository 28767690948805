import { RootStore } from '@src/stores/RootStore'
import { createContext, useContext } from 'react'

let rootStore: RootStore

const StoreContext = createContext<RootStore | undefined>(undefined);

export function RootStoreProvider({ children }) {
  const root = rootStore ?? new RootStore()

  return (
    <StoreContext.Provider value={root}>
      {children}
    </StoreContext.Provider>
  )
}

export function useRootStore(): RootStore {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider")
  }

  return context
}
