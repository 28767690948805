import {useMediaQuery} from "usehooks-ts"
import web3Project from './web3-project.png'
import web3ProjectMobile from './web3-project-mobile.png'
import './BuildingPlatformPage.css'
import {useEffect, useRef} from "react"
import config, {fadeInOptions} from "@src/config"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {gsap} from "gsap"
import {useTranslation} from "react-i18next";

let once;

export default function BuildingPlatformPage() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  const start = isMobile ? 'top center' : '+=4000 center'
  const end = isMobile ? undefined : '+=4000 center'

  useEffect(() => {
    if (once) {
      return;
    }
    once = true;

    ScrollTrigger.create({
      trigger: '.buildingPlatformTitleSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.buildingPlatformTitle', fadeInOptions)
    })

    ScrollTrigger.create({
      trigger: '.web3ProjectSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.web3ProjectFigure', fadeInOptions)
    })

    ScrollTrigger.create({
      trigger: '.web3ProjectDescriptionSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.web3ProjectDescription', fadeInOptions)
        .from('.web3ProjectDescriptionVideo', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })
  })

  return (
    <div className="relative">
      <BuildingPlatformTitleSection />
      <Web3ProjectSection />
      <Web3ProjectDescriptionSection />
    </div>
  )
}

function BuildingPlatformTitleSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="bgGridY bg-[#4545C9]">
      <div className="bgGridX">
        {isMobile ? (
          <div className="buildingPlatformTitleSection grid grid-cols-12 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)]">
            <div className="col-start-11 col-end-12 row-start-5 row-end-6 bg-white" />
            <div className="col-start-2 col-end-3 row-start-14 row-end-15 bg-white" />
            <h1 className="buildingPlatformTitle col-start-2 col-end-23 row-start-7 row-end-14 flex flex-col justify-start items-start xs:leading-relaxed font-semibold text-white"
                style={{ fontSize: 'clamp(2rem, 7.813vw, 3.75rem)' }}>
              <div>Web3</div>
              <div className="relative w-full flex items-start overflow-y-hidden">
                &zwnj;
                <span className="absolute" style={{ top: '0', animation: 'roller 4s infinite' }}>
                  Project<br />
                  Community<br />
                  Project
                </span>
              </div>
              <div>Building Platform</div>
            </h1>
          </div>
        ) : (
          <div className="buildingPlatformTitleSection grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <h1 className="buildingPlatformTitle col-start-3 col-end-23 row-start-4 row-end-12 flex flex-col justify-start items-start leading-relaxed pl-8 font-semibold text-white"
                style={{ fontSize: 'var(--section-title-font-size)' }}>
              <div>Web3</div>
              <div className="relative w-full flex items-start overflow-y-hidden">
                &zwnj;
                <span className="absolute" style={{ top: '0', animation: 'roller 4s infinite' }}>
                  Project<br />
                  Community<br />
                  Project
                </span>
              </div>
              <div>Building Platform</div>
            </h1>
            <div className="col-start-22 col-end-23 row-start-3 row-end-4 bg-white" />
          </div>
        )}
      </div>
    </section>
  )
}

function Web3ProjectSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="web3ProjectSection w-[var(--grid-unit-12)] h-[var(--grid-unit-18)] px-[var(--grid-unit-1)] pt-[var(--grid-unit-3)] pb-[var(--grid-unit-1)]">
            <figure className="web3ProjectFigure w-full h-full flex justify-center items-center">
              <img src={web3ProjectMobile} alt="web3 project" />
            </figure>
          </div>
        ) : (
          <div className="web3ProjectSection w-[var(--grid-unit-24)] h-[var(--grid-unit-12)] px-[var(--grid-unit-4)] py-[var(--grid-unit-1)]">
            <figure className="web3ProjectFigure w-full h-full flex justify-center items-center">
              <img src={web3Project} alt="web3 project" />
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}

function Web3ProjectDescriptionSection() {
  const { t } = useTranslation()
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMobile = !useMediaQuery('(min-width: 768px)')

  useEffect(() => {
    videoRef.current?.play().then()
  }, [])

  return (
    <section className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="web3ProjectDescriptionSection grid grid-cols-12 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)]">
            <div className="col-start-10 col-end-11 row-start-2 row-end-3 bg-[#4545C9]" />
            <div className="web3ProjectDescription col-start-3 col-end-11 row-start-3 row-end-15 flex flex-col justify-evenly items-start xs:pb-[var(--grid-unit-1)] pl-2 xs:pl-8 font-bold"
                 style={{ fontSize: 'clamp(1.2rem, 5.208vw, 2.5rem)' }}>
              <div>
                {t('building.description.title1')}<br/>
                {t('building.description.title2')}
              </div>
              <div>
                {t('building.description.desc1')}<br/>
                {t('building.description.desc2')}
              </div>
              <div>
                {t('building.description.desc3')}<br />
                {t('building.description.desc4')}<br/>
                {t('building.description.desc5')}
              </div>
            </div>
            <figure className="web3ProjectDescriptionVideo relative col-start-1 col-end-13 row-start-16 row-end-19">
              <div className="absolute inset-0">
                <img className="h-full object-cover" src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-build-frame001.png`} alt="1egdao build" />
              </div>
              <video ref={videoRef} className="absolute inset-0 h-full object-cover" preload="metadata" muted loop playsInline>
                <source src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-build.mp4`} type="video/mp4" />
              </video>
            </figure>
          </div>
        ) : (
          <div className="web3ProjectDescriptionSection grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <div className="web3ProjectDescription col-start-3 col-end-22 row-start-1 row-end-9 flex flex-col justify-evenly items-start pl-8">
              <div className="font-bold" style={{ fontSize: 'clamp(1.5rem, 3.750vw, 4.5rem)' }}>
                {t('building.description.title1')}<br/>
                {t('building.description.title2')}
              </div>
              <div className="font-semibold" style={{ fontSize: 'clamp(1.2rem, 2.500vw, 3rem)' }}>
                {t('building.description.desc1')} {t('building.description.desc2')}<br/>
                {t('building.description.desc3')} {t('building.description.desc4')}<br/>
                {t('building.description.desc5')}
              </div>
            </div>
            <div className="col-start-22 col-end-23 row-start-2 row-end-3 bg-[#4545C9]" />
            <figure className="web3ProjectDescriptionVideo relative col-start-1 col-end-25 row-start-10 row-end-13">
              <div className="absolute inset-0">
                <img className="h-full object-fill" src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-build-frame001.png`} alt="1egdao build" />
              </div>
              <video ref={videoRef} className="absolute inset-0 h-full object-fill" preload="metadata" muted loop playsInline>
                <source src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-build.mp4`} type="video/mp4" />
              </video>
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}
