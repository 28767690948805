import { action, makeAutoObservable } from 'mobx'
import { RootStore } from '@src/stores/RootStore'

export class UserStore {
  private rootStore: RootStore

  isSignedIn: boolean = false

  constructor(rootStore) {
    makeAutoObservable(this)

    this.rootStore = rootStore
  }

  @action
  setSignedIn(isSignedIn: boolean) {
    this.isSignedIn = isSignedIn
  }
}
