import { Controller, useForm } from 'react-hook-form'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'


interface CheckTermsAgreeModalProps {
  buttonLabel?: string
  isTermsAgreeModalOpen: boolean
  setIsTermsAgreeModalOpen: (isOpen: boolean) => void
  onSubmitConfirmed: (data: { privacyCollect: boolean, privacyProvide: boolean, marketingAgree: boolean }) => void
}

// TODO: cleansing unused code: privacyProvide, marketingAgree
export default function CheckTermsAgreeModal({ buttonLabel, isTermsAgreeModalOpen, setIsTermsAgreeModalOpen, onSubmitConfirmed }: CheckTermsAgreeModalProps) {
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: {
    // allAgree: false,
    privacyCollect: false,
    // privacyProvide: false,
    // marketingAgree: false,
  }})
  const [isValidToSubmit, setIsValidToSubmit] = useState(false)

  const onSubmit = async data => {
    onClose()
    onSubmitConfirmed({
      privacyCollect: data.privacyCollect,
      privacyProvide: false,
      marketingAgree: false,
    })
  }

  // const onAllAgreeChanged = checked => {
  //   setValue('privacyCollect', checked)
  //   setValue('privacyProvide', checked)
  //   setValue('marketingAgree', checked)
  //
  //   setIsValidToSubmit(checked)
  // }

  const onPrivacyCollectChanged = checked => {
    // if (!checked) {
    //   setAllAgreeToUnchecked()
    // }
    setIsValidToSubmit(checked)
  }

  // const onPrivacyProvideChanged = checked => {
  //   if (!checked) {
  //     setAllAgreeToUnchecked()
  //   }
  // }

  // const onMarketingAgreeChanged = checked => {
  //   if (!checked) {
  //     setAllAgreeToUnchecked()
  //   }
  // }

  // function setAllAgreeToUnchecked() {
  //   setValue('allAgree', false)
  // }

  const onClose = () => {
    reset()
    setIsTermsAgreeModalOpen(false)
  }

  return (
    <Transition.Root show={isTermsAgreeModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all w-[500px]">
                <div className="py-[50px] px-[50px]">
                  <div className="absolute top-8 right-8 cursor-pointer" onClick={onClose}>
                    <XMarkIcon className="h-6 w-6 text-[#4545C9]" strokeWidth={3} />
                  </div>
                  <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="font-[700] text-[#4545C9] text-[36px] leading-[50px]">
                      Welcome to<br/>
                      1egDAO
                    </h3>
                    <div className="pt-[24px] text-[15px]">
                      1egDAO 가입을 위해 개인정보 이용 및 수집에 동의가 필요합니다.<br/>
                      동의하지 않을 경우 가입 및 참여에 제한이 될 수 있습니다.
                    </div>
                    <div className="mt-[60px] mb-[60px]">
                      {/*<div className="relative flex items-start">*/}
                      {/*  <div className="flex h-5 items-center">*/}
                      {/*    <Controller*/}
                      {/*      control={control}*/}
                      {/*      name="allAgree"*/}
                      {/*      render={({ field: { onChange, onBlur, value, name, ref }}) => (*/}
                      {/*        <input*/}
                      {/*          ref={ref}*/}
                      {/*          id={name}*/}
                      {/*          type="checkbox"*/}
                      {/*          name={name}*/}
                      {/*          className="h-[28px] w-[28px] rounded accent-[#4545C9]"*/}
                      {/*          onBlur={onBlur}*/}
                      {/*          onChange={event => {*/}
                      {/*            onChange(event)*/}
                      {/*            onAllAgreeChanged(event.target.checked)*/}
                      {/*          }}*/}
                      {/*          checked={value}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  <div className="ml-3 text-sm">*/}
                      {/*    <label htmlFor="allAgree" className="font-bold text-[20px] select-none">*/}
                      {/*      모두 동의*/}
                      {/*    </label>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <div className="relative flex justify-between items-center">
                        <div className="flex justify-center h-5 items-center">
                          <Controller
                            control={control}
                            name="privacyCollect"
                            render={({ field: { onChange, value, ref }}) => (
                              <input
                                ref={ref}
                                id="privacyCollect"
                                type="checkbox"
                                className="h-[28px] w-[28px] rounded accent-[#4545C9]"
                                onChange={event => {
                                  onChange(event)
                                  onPrivacyCollectChanged(event.target.checked)
                                }}
                                checked={value}
                              />
                            )}
                          />
                        </div>
                        <div className="flex-1 ml-3 text-sm flex flex-row justify-between items-center">
                          <label htmlFor="privacyCollect" className="flex flex-row items-center text-[18px] select-none whitespace-nowrap">
                            <div className="ml-1 mr-3">[필수]</div>
                            <div>
                              <span className="font-bold">개인정보 수집·이용</span>에 동의합니다.
                            </div>
                          </label>
                          <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
                            <ChevronRightIcon className="w-[20px] h-[20px] cursor-pointer"/>
                          </a>
                        </div>
                      </div>
                      {/*<div className="relative flex items-start">*/}
                      {/*  <div className="flex h-5 items-center">*/}
                      {/*    <Controller*/}
                      {/*      control={control}*/}
                      {/*      name="privacyProvide"*/}
                      {/*      render={({ field: { onChange, onBlur, value, name, ref }}) => (*/}
                      {/*        <input*/}
                      {/*          ref={ref}*/}
                      {/*          id={name}*/}
                      {/*          type="checkbox"*/}
                      {/*          name={name}*/}
                      {/*          className="h-[28px] w-[28px] rounded accent-[#4545C9]"*/}
                      {/*          onBlur={onBlur}*/}
                      {/*          onChange={event => {*/}
                      {/*            onChange(event)*/}
                      {/*            onPrivacyProvideChanged(event.target.checked)*/}
                      {/*          }}*/}
                      {/*          checked={value}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  <div className="ml-3 text-sm flex flex-1 justify-between items-center">*/}
                      {/*    <label htmlFor="privacyProvide" className="text-[18px] select-none">*/}
                      {/*      [선택] <span className="font-bold">개인정보 제3자 제공</span>에 동의합니다.*/}
                      {/*    </label>*/}
                      {/*    <a href="/privacy2.html" target="_blank" rel="noopener noreferrer">*/}
                      {/*      <ChevronRightIcon className="w-[20px] h-[20px]" />*/}
                      {/*    </a>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="relative flex items-start">*/}
                      {/*  <div className="flex h-5 items-center">*/}
                      {/*    <Controller*/}
                      {/*      control={control}*/}
                      {/*      name="marketingAgree"*/}
                      {/*      render={({ field: { onChange, onBlur, value, name, ref }}) => (*/}
                      {/*        <input*/}
                      {/*          ref={ref}*/}
                      {/*          id={name}*/}
                      {/*          type="checkbox"*/}
                      {/*          name={name}*/}
                      {/*          className="h-[28px] w-[28px] rounded accent-[#4545C9]"*/}
                      {/*          onBlur={onBlur}*/}
                      {/*          onChange={event => {*/}
                      {/*            onChange(event)*/}
                      {/*            onMarketingAgreeChanged(event.target.checked)*/}
                      {/*          }}*/}
                      {/*          checked={value}*/}
                      {/*        />*/}
                      {/*      )}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*  <div className="ml-3 text-sm flex flex-1 justify-between items-center">*/}
                      {/*    <label htmlFor="marketingAgree" className="text-[18px] select-none">*/}
                      {/*      [선택] <span className="font-bold">마케팅 정보 수신</span>에 동의합니다.*/}
                      {/*    </label>*/}
                      {/*    <a href="/marketing.html" target="_blank" rel="noopener noreferrer">*/}
                      {/*      <ChevronRightIcon className="w-[20px] h-[20px]" />*/}
                      {/*    </a>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                    <button
                      type="submit"
                      className={classNames("w-full h-[55px] rounded-[30px] text-[20px] font-bold transition-colors duration-300 flex justify-center items-center", {
                        'bg-[#4545C9] text-white': isValidToSubmit,
                        'bg-[#F0F0F0] text-[rgba(0,0,0,0.5)] cursor-default': !isValidToSubmit,
                      })}
                      disabled={!isValidToSubmit}
                    >
                      {buttonLabel ? buttonLabel : '지원 시작하기'}
                    </button>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
