import axios from 'axios'
import config from '@src/config'

const axiosClient = axios.create({
  baseURL: config.app.apiEndpoint,
  timeout: config.app.apiTimeoutMillis,
  withCredentials: true,
})

axios.defaults.withCredentials = true

export default axiosClient
