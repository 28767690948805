import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ApplicationNavigator from '@src/pages/apply/season-1-application/ApplicationNavigator'
import classNames from 'classnames'
import { contentBorderClass } from '@src/Styles'
import RippleEffect from '@src/components/effects/RippleEffect'
import React, { useEffect, useState } from 'react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import icons from './icons'
import _ from 'lodash'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'


const ApplicationSection2 = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleSubmit, setValue } = useForm()
  const { applicationStore } = useRootStore()
  const [pageIdx, setPageIdx] = useState(0)
  const [web3Perspective, setWeb3Perspective] = useState<string>('')
  const [web3Ability, setWeb3Ability] = useState<string>('')
  const [web3Industries, setWeb3Industries] = useState<string[]>([])

  const currentSection = searchParams.get('section') || 1

  useEffect(() => {
    setWeb3Perspective(applicationStore.web3Perspective)
    setWeb3Ability(applicationStore.web3Ability)
    setWeb3Industries(applicationStore.web3Industries)
  }, [])

  const onSubmit = data => {
    applicationStore.web3Perspective = web3Perspective
    applicationStore.web3Ability = web3Ability
    applicationStore.web3Industries = web3Industries

    const nextPageIdx = pageIdx + 1
    if (nextPageIdx > 2) {
      navigate({
        pathname: '/apply/season-1-application',
        search: '?section=3',
      })
    } else {
      setPageIdx(pageIdx + 1)
    }
    window.scrollTo(0, 0)
  }

  const handlePreviousClick = () => {
    if (pageIdx === 0) {
      navigate({
        pathname: '/apply/season-1-application',
        search: '?section=1',
      })
    } else {
      setPageIdx(pageIdx - 1)
    }
    window.scrollTo(0, 0)
  }

  const isPageCompleted = (idx) => {
    if (idx === 0) {
      return !_.isEmpty(web3Perspective)
    }

    if (idx === 1) {
      return !_.isEmpty(web3Ability) && isPageCompleted(0)
    }

    if (idx === 2) {
      return web3Industries.length === 2 && isPageCompleted(1)
    }

    console.error(`idx ${idx} does not exist`)
    return false
  }

  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <nav className="h-[var(--grid-unit-1)] w-[var(--grid-unit-15)]">
          <ApplicationNavigator curSectionNum={currentSection} />
        </nav>
        <section className={classNames("w-full bg-white", contentBorderClass)}>
          <form className="relative p-[80px] mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center"
                onSubmit={handleSubmit(onSubmit, console.warn)}>
            <nav className="space-x-2 flex items-center">
              <div className={classNames("inline-block w-[30px] h-[30px] border border-[#4545C9] rounded-full", {
                     'bg-[#4545C9]': pageIdx === 0,
                     'cursor-pointer': pageIdx !== 0,
                   })}
                   onClick={() => {
                     setPageIdx(0)
                   }} />
              <div className={classNames("inline-block w-[30px] h-[30px] border border-[#4545C9] rounded-full", {
                     'bg-[#4545C9]': pageIdx === 1,
                     'cursor-pointer': isPageCompleted(0),
                     'cursor-not-allowed': !isPageCompleted(0),
                   })}
                   onClick={() => {
                     if (isPageCompleted(0)) {
                       setPageIdx(1)
                     }
                   }} />
              <div className={classNames("inline-block w-[30px] h-[30px] border border-[#4545C9] rounded-full", {
                    'bg-[#4545C9]': pageIdx === 2,
                    'cursor-pointer': isPageCompleted(1),
                    'cursor-not-allowed': !isPageCompleted(1),
                   })}
                   onClick={() => {
                     if (isPageCompleted(1)) {
                       setPageIdx(2)
                     }
                   }} />
            </nav>

            <div className="mt-[36px] mb-[93px]">
              {pageIdx === 0 && (
                <CheckWeb3Perspective web3Perspective={web3Perspective} setWeb3Perspective={setWeb3Perspective} />
              )}
              {pageIdx === 1 && (
                <CheckWeb3Ability web3Ability={web3Ability} setWeb3Ability={setWeb3Ability} />
              )}
              {pageIdx === 2 && (
                <CheckWeb3Industries web3Industries={web3Industries} setWeb3Industries={setWeb3Industries} />
              )}
            </div>

            <div className="relative grid grid-cols-5">
              <div className="col-start-1 col-span-1 flex justify-start items-center cursor-pointer text-[#808080]" onClick={handlePreviousClick}>
                <ChevronLeftIcon className="w-[42px] h-[42px]" />
                <span>이전</span>
              </div>
              <button
                type="submit"
                className={classNames("col-start-2 col-span-3 select-none self-center h-[80px] text-[30px] text-white rounded-[40px] font-bold flex justify-center items-center", {
                  'bg-[#4545C9] text-white cursor-pointer': isPageCompleted(pageIdx),
                  'bg-[#F1F1F1] text-[rgba(0,0,0,0.5)] cursor-not-allowed': !isPageCompleted(pageIdx),
                })}
                disabled={!isPageCompleted(pageIdx)}
              >
                {pageIdx === 2 ? '완 료' : '다 음'}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}

const web3Perspectives = [{
  value: 'technology',
  icon: icons.TechnologyIcon,
  title: 'Technology',
  description1: '블록체인 기술의 적용과',
  description2: '기술이 가져올 세상의 변화',
}, {
  value: 'society',
  icon: icons.SocietyIcon,
  title: 'Society',
  description1: '탈중앙화 가치에 대한 공감과',
  description2: '사회 전반에 미칠 영향',
}, {
  value: 'culture',
  icon: icons.CultureIcon,
  title: 'Culture',
  description1: '웹3가 가져올 기존에 없었던',
  description2: '새로운 문화의 흐름',
}, {
  value: 'economy',
  icon: icons.EconomyIcon,
  title: 'Economy',
  description1: '피할 수 없는 경제의 흐름.',
  description2: '새로운 투자 방식',
}, {
  value: 'community',
  icon: icons.CommunityIcon,
  title: 'Community',
  description1: '관계와 커뮤니티,',
  description2: '결국 웹3의 기반도 사람',
}]

function CheckWeb3Perspective({ web3Perspective, setWeb3Perspective }) {
  return (
    <div className="relative">
      <h3 className="mb-[45px] text-[32px] font-bold">
        당신은 웹3를 어떤 관점에 집중하여 바라보고 있나요?
      </h3>
      <ul className="flex justify-center gap-[60px] flex-wrap">
        {web3Perspectives.map(perspective => {
          const bg = web3Perspective === perspective.value ? 'rgba(204, 204, 236, .3)' : undefined
          return (
            <RippleEffect key={perspective.value}>
              <li className="select-none ripple-bg-[#CCCCEC] flex flex-col justify-center items-center w-[240px] h-[240px] rounded-[40px] border-2 border-[rgba(204,204,236,.3)] cursor-pointer transition-bg duration-300 hover:border-[#CCCCEC] hover:bg-[rgba(204,204,236,.3)]"
                  style={{ backgroundColor: bg}}
                  onClick={() => setWeb3Perspective(perspective.value)}>
                <img className="w-[70px] h-[70px]" src={perspective.icon} />
                <dt className="font-semibold text-[28px] my-2">
                  {perspective.title}
                </dt>
                <dd className="text-[14px] flex flex-col items-center">
                  <span>{perspective.description1}</span>
                  <span>{perspective.description2}</span>
                </dd>
              </li>
            </RippleEffect>
          )
        })}
      </ul>
    </div>
  )
}

const web3Abilities = [{
  value: 'builder',
  icon: icons.BuilderIcon,
  title: 'Builder',
  description1: '프로젝트를 빌딩하며',
  description2: '새로운 가치를 만들어내기',
}, {
  value: 'trader',
  icon: icons.TraderIcon,
  title: 'Trader',
  description1: '시장의 흐름을',
  description2: '파악하고 투자하기',
}, {
  value: 'vc',
  icon: icons.VcIcon,
  title: 'VC',
  description1: '거시적인 관점을 갖고',
  description2: '기관의 입장에서 투자하기',
}, {
  value: 'marketer',
  icon: icons.MarketerIcon,
  title: 'Marketer',
  description1: '프로젝트를 외부로 알리고,',
  description2: '다양한 파트너십 맺기',
}, {
  value: 'strategist',
  icon: icons.StrategistIcon,
  title: 'Strategist',
  description1: '웹3 생태계를 이해하며,',
  description2: '프로덕트의 구조 및 전략 짜기',
}, {
  value: 'researcher',
  icon: icons.ResearcherIcon,
  title: 'Researcher',
  description1: '웹3씬을 자신의 관점으로',
  description2: '리서치하고 콘텐츠 만들기',
}, {
  value: 'engineer',
  icon: icons.EngineerIcon,
  title: 'Engineer',
  description1: '코드로 아이디어와',
  description2: '서비스를 구현하기',
}, {
  value: 'artist',
  icon: icons.ArtistIcon,
  title: 'Artist',
  description1: '미술, 음악, 사진 등 웹3 위에서',
  description2: '자신의 예술성을 표현하기',
}, {
  value: 'communicator',
  icon: icons.CommunicatorIcon,
  title: 'Communicator',
  description1: '커뮤니티와 사람들의 니즈를',
  description2: '파악하고 소통하기',
}, {
  value: 'influencer',
  icon: icons.InfluencerIcon,
  title: 'Influencer',
  description1: '웹3 경험과 인사이트를 통해',
  description2: '영향력을 끼치기',
}, {
  value: 'web3Lover',
  icon: icons.Web3loverIcon,
  title: 'Web3 Lover',
  description1: '웹3의 가능성을 알아보고,',
  description2: '이 씬 자체를 즐기기',
}]

function CheckWeb3Ability({ web3Ability, setWeb3Ability }) {
  return (
    <div className="relative">
      <div className="mb-[45px]">
        <h3 className="text-[32px] font-bold">
          앞으로 웹3 위에서 수많은 기회가 펼쳐질 것입니다.<br/>
          그 기회들과 연결될 수 있는 나의 역량은 무엇인가요?
        </h3>
        <div className="mt-4 text-[24px]">
          (아직 웹3 경험이 많지 않다면, 앞으로 쌓아가고 싶은 역량을 선택해주세요.)
        </div>
      </div>
      <ul className="flex justify-center gap-[60px] flex-wrap">
        {web3Abilities.map(perspective => {
          const bg = web3Ability === perspective.value ? 'rgba(204, 204, 236, .3)' : undefined
          return (
            <RippleEffect key={perspective.value}>
              <li className="select-none ripple-bg-[#CCCCEC] flex flex-col justify-center items-center w-[240px] h-[240px] rounded-[40px] border-2 border-[rgba(204,204,236,.3)] cursor-pointer transition-bg duration-300 hover:border-[#CCCCEC] hover:bg-[rgba(204,204,236,.3)]"
                  style={{ backgroundColor: bg}}
                  onClick={() => setWeb3Ability(perspective.value)}>
                <img className="w-[70px] h-[70px]" src={perspective.icon} />
                <dt className="font-semibold text-[28px] my-2">
                  {perspective.title}
                </dt>
                <dd className="text-[14px] flex flex-col items-center">
                  <span>{perspective.description1}</span>
                  <span>{perspective.description2}</span>
                </dd>
              </li>
            </RippleEffect>
          )
        })}
      </ul>
    </div>
  )
}

const web3IndustryOptions = [{
  value: 'art',
  icon: icons.ArtIcon,
  title: '아트',
}, {
  value: 'music',
  icon: icons.MusicIcon,
  title: '음악',
}, {
  value: 'brand',
  icon: icons.BrandIcon,
  title: '브랜드',
}, {
  value: 'entertainment',
  icon: icons.EntertainmentIcon,
  title: '엔터테인먼트',
}, {
  value: 'leisure',
  icon: icons.LeisureIcon,
  title: '문화 / 여가',
}, {
  value: 'sports',
  icon: icons.SportsIcon,
  title: '스포츠',
}, {
  value: 'game',
  icon: icons.GameIcon,
  title: '게임',
}, {
  value: 'realestate',
  icon: icons.RealestateIcon,
  title: '부동산',
}, {
  value: 'finance',
  icon: icons.FinanceIcon,
  title: '금융',
}, {
  value: 'ecommerce',
  icon: icons.EcommerceIcon,
  title: 'e-커머스',
}, {
  value: 'contents',
  icon: icons.ContentsIcon,
  title: '콘텐츠 플랫폼',
}, {
  value: 'it',
  icon: icons.ItIcon,
  title: 'IT',
}, {
  value: 'fnb',
  icon: icons.FnbIcon,
  title: 'F&B',
}, {
  value: 'hr',
  icon: icons.HrIcon,
  title: 'HR',
}, {
  value: 'education',
  icon: icons.EducationIcon,
  title: '교육',
}, {
  value: 'insurance',
  icon: icons.InsuranceIcon,
  title: '보험',
}, {
  value: 'medicine',
  icon: icons.MedicineIcon,
  title: '의료',
}, {
  value: 'public',
  icon: icons.PublicOrganizationIcon,
  title: '공공/인증기관',
}, {
  value: 'delivery',
  icon: icons.DeliveryIcon,
  title: '물류/유통',
}, {
  value: 'esg',
  icon: icons.EsgIcon,
  title: 'ESG',
}]

function CheckWeb3Industries({ web3Industries, setWeb3Industries }) {
  return (
    <div className="relative">
      <h3 className="mb-[45px] text-[32px] font-bold">
        웹3는 하나의 흐름이기에 실제 가치와 연결되어,<br />
        다양한 분야에 적용될 수 있습니다.<br />
        웹3를 적용하고 싶은 2가지 영역을 선택해주세요.
      </h3>
      <ul className="flex justify-center gap-[60px] flex-wrap">
        {web3IndustryOptions.map(industry => {
          const bg = web3Industries.includes(industry.value) ? 'rgba(204, 204, 236, .3)' : undefined
          return (
            <RippleEffect key={industry.value}>
              <li className="select-none ripple-bg-[#CCCCEC] pl-8 flex flex-row justify-left items-center w-[276px] h-[100px] rounded-[50px] border-2 border-[rgba(204,204,236,.3)] cursor-pointer transition-bg duration-300 hover:border-[#CCCCEC] hover:bg-[rgba(204,204,236,.3)]"
                  style={{ backgroundColor: bg}}
                  onClick={() => {
                    if (web3Industries.includes(industry.value)) {
                      return
                    }

                    const nextWeb3Industries = [...web3Industries, industry.value]
                    for (let i=nextWeb3Industries.length; i>2; i--) {
                      nextWeb3Industries.shift()
                    }

                    setWeb3Industries(nextWeb3Industries)
                  }}>
                <img className="inline-block h-[50px] mr-4" src={industry.icon} />
                <div className="font-bold text-[26px] my-2">
                  {industry.title}
                </div>
              </li>
            </RippleEffect>
          )
        })}
      </ul>
    </div>
  )
}

export default observer(ApplicationSection2)
