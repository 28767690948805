import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import Header from './global/Header'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'


export const DEBUG = false

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, MotionPathPlugin)

function AppTemplate() {
  const location = useLocation()

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <Header useLogo={location.pathname !== '/'} />
      <div className="relative break-keep">
        <Outlet />
      </div>
    </>
  )
}

export default AppTemplate
