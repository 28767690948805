import {useEffect, useRef} from "react";
import cx from 'classnames';
import {contentBorderClass} from "@src/Styles";
import {useMediaQuery} from "usehooks-ts";
import selectedMember from './selected-member.png'
import tokenOfTrust from './token-of-trust.png'
import customGenerativeArt from './custom-generative-art.png'
import config, {fadeInOptions} from "@src/config"
import {gsap} from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import './SbtPage.css'
import {useTranslation} from "react-i18next";

let once = false;

export default function SbtPage() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  useEffect(() => {
    if (once) {
      return;
    }
    once = true;

    const start = isMobile ? 'top center' : '+=4000 center'
    const end = isMobile ? undefined : '+=4000 center'

    ScrollTrigger.create({
      trigger: '.sbtTitleSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.sbtTitle', fadeInOptions)
        .from('.sbtDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

    ScrollTrigger.create({
      trigger: '.selectedMembershipSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.selectedMembershipTitle', fadeInOptions)
        .from('.selectedMembershipFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.selectedMembershipContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

    ScrollTrigger.create({
      trigger: '.tokenOfTrustSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.tokenOfTrustTitle', fadeInOptions)
        .from('.tokenOfTrustFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.tokenOfTrustContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

    ScrollTrigger.create({
      trigger: '.customGenerativeArtSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.customGenerativeArtTitle', fadeInOptions)
        .from('.customGenerativeArtFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.customGenerativeArtContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

    ScrollTrigger.create({
      trigger: '.membershipSection',
      start: start,
      end: end,
      animation: gsap.timeline()
        .from('.membershipTitle', fadeInOptions)
        .from('.selectiveSbt', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.selectiveSbtContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.foundingNft', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.foundingNftContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.selectiveToken', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.selectiveTokenContent', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
    })

  }, [])

  return (
    <div className="relative">
      <TitleSection />
      <SbtBenefitSection />
      <MembershipSection />
      <ConnectUsSection />
    </div>
  )
}

function TitleSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section id="membership" className="bgGridY bg-[#4545C9]">
      <div className="bgGridX">
        {isMobile ? (
          <div className="sbtTitleSection grid grid-cols-12 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)]">
            <div className="col-start-3 col-end-4 row-start-5 row-end-6 bg-white" />
            <div className="col-start-3 col-end-11 row-start-6 row-end-17 xs:row-end-13 flex flex-col justify-evenly text-white px-2 xs:p-4">
              <h1 className="sbtTitle xs:leading-relaxed font-semibold" style={{ fontSize: 'clamp(1.5rem, 7.813vw, 3.75rem)' }}>
                1eg
                <br />
                Selective SBT
              </h1>
              <div className="sbtDescription leading-relaxed xs:leading-loose lg:mt-8 font-medium" style={{ fontSize: 'clamp(1.2rem, 3.906vw, 1.875rem)' }}>
                Mint your potential.
                <br />
                Unlock Opportunities.
              </div>
            </div>
          </div>
        ) : (
          <div className="sbtTitleSection grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <div className="col-start-3 col-end-4 row-start-3 row-end-4 bg-white" />
            <div className="col-start-4 col-end-22 row-start-4 row-end-12 text-white px-2 lg:p-4">
              <h1 className="sbtTitle leading-snug font-semibold" style={{ fontSize: 'var(--section-title-font-size)' }}>
                1eg
                <br />
                Selective SBT
              </h1>
              <div className="sbtDescription mt-4 lg:mt-8 font-medium" style={{ fontSize: 'clamp(1.5rem, 3.125vw, 3.75rem)' }}>
                Mint your potential.
                <br />
                Unlock Opportunities.
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

function SbtBenefitSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <>
            <article className="selectedMembershipSection relative grid grid-cols-12 grid-rows-8 w-[var(--grid-unit-12)] h-[var(--grid-unit-8)]">
              <h2 className="selectedMembershipTitle col-start-2 col-end-9 row-start-3 row-end-5 flex justify-start items-center pl-[6vw] font-semibold text-[#4545C9]"
                  style={{ fontSize: '5.208vw' }}>
                Selected
                <br />
                Members
              </h2>
              <figure className="selectedMembershipFigure col-start-10 col-end-12 row-start-3 row-end-5 flex justify-start items-center p-[1vw]">
                <img className="object-cover" src={selectedMember} alt="selected members" />
              </figure>
              <p className={cx("selectedMembershipContent relative col-start-2 col-end-11 row-start-5 row-end-9 flex justify-start items-start px-[6vw] pt-[5vw] font-bold bg-white", contentBorderClass)}
                 style={{ fontSize: 'clamp(0.8rem, 3.125vw, 1.5rem)' }}>
                {t('sbt.benefit.selectedMembership1')}<br />
                {t('sbt.benefit.selectedMembership2')}
              </p>
            </article>
            <article className="tokenOfTrustSection relative grid grid-cols-12 grid-rows-8 w-[var(--grid-unit-12)] h-[var(--grid-unit-8)]">
              <div className="col-start-11 col-end-12 row-start-1 row-end-2 bg-[#9898DA]" />
              <h2 className="tokenOfTrustTitle col-start-7 col-end-12 row-start-3 row-end-5 flex justify-end items-center pr-[3vw] font-semibold text-[#4545C9]"
                  style={{ fontSize: '5.208vw' }}>
                Token of Trust
              </h2>
              <figure className="tokenOfTrustFigure col-start-2 col-end-4 row-start-3 row-end-5 flex justify-start items-center p-[2vw]">
                <img className="object-cover" src={tokenOfTrust} alt="token of trust" />
              </figure>
              <p className={cx("tokenOfTrustContent relative col-start-3 col-end-12 row-start-5 row-end-9 flex justify-start items-start px-[6vw] pt-[5vw] font-bold bg-white", contentBorderClass)}
                 style={{ fontSize: 'clamp(0.8rem, 3.125vw, 1.5rem)' }}>
                {t('sbt.benefit.tokenOfTrust1')}<br />
                {t('sbt.benefit.tokenOfTrust2')}
              </p>
            </article>
            <article className="customGenerativeArtSection relative grid grid-cols-12 grid-rows-9 w-[var(--grid-unit-12)] h-[var(--grid-unit-9)]">
              <div className="col-start-2 col-end-3 row-start-1 row-end-2 bg-[#CCCCEC]" />
              <h2 className="customGenerativeArtTitle col-start-2 col-end-9 row-start-3 row-end-5 flex justify-start items-center px-[6vw] font-semibold text-[#4545C9]"
                  style={{ fontSize: '5.208vw' }}>
                Custom<br />
                Generative Art
              </h2>
              <figure className="customGenerativeArtFigure col-start-10 col-end-12 row-start-3 row-end-5 flex justify-start items-center p-[2vw]">
                <img className="object-cover" src={customGenerativeArt} alt="custom generative art" />
              </figure>
              <p className={cx("customGenerativeArtContent relative col-start-2 col-end-11 row-start-5 row-end-9 flex justify-start items-start px-[6vw] pt-[5vw] font-bold bg-white", contentBorderClass)}
                 style={{ fontSize: 'clamp(0.8rem, 3.125vw, 1.5rem)' }}>
                {t('sbt.benefit.customGenerativeArt1')}<br />
                {t('sbt.benefit.customGenerativeArt2')}
              </p>
            </article>
          </>
        ) : (
          <div className="grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <div className="col-start-23 col-end-24 row-start-10 row-end-11 bg-[#4545C9]" />
            <article className={cx("selectedMembershipSection col-start-2 col-end-9 row-start-3 row-end-10 grid grid-cols-7 grid-rows-7 w-[var(--grid-unit-7)] h-[var(--grid-unit-7)] bg-white", contentBorderClass)}>
              <h2 className="selectedMembershipTitle col-start-1 col-end-5 row-start-1 row-end-4 benefitTitle">
                Selected Members
              </h2>
              <div className="selectedMembershipContent col-start-1 col-end-8 row-start-4 row-end-8 benefitDescription">
                {t('sbt.benefit.selectedMembership1')}<br />
                {t('sbt.benefit.selectedMembership2')}
              </div>
              <figure className="selectedMembershipFigure col-start-5 col-end-8 row-start-1 row-end-4 benefitFigure">
                <img className="object-contain" src={selectedMember} alt="selected members" />
              </figure>
            </article>
            <article className={cx("tokenOfTrustSection relative flex flex-col col-start-9 col-end-16 row-start-5 row-end-12 grid grid-cols-7 grid-rows-7 w-[var(--grid-unit-7)] h-[var(--grid-unit-7)] bg-white", contentBorderClass)}>
              <h2 className="tokenOfTrustTitle col-start-4 col-end-8 row-start-1 row-end-4 benefitTitle">
                Token of Trust
              </h2>
              <div className="tokenOfTrustContent col-start-1 col-end-8 row-start-4 row-end-8 benefitDescription">
                {t('sbt.benefit.tokenOfTrust1')}<br />
                {t('sbt.benefit.tokenOfTrust2')}
              </div>
              <figure className="tokenOfTrustFigure col-start-1 col-end-4 row-start-1 row-end-4 benefitFigure">
                <img className="object-contain" src={tokenOfTrust} alt="token of trust" />
              </figure>
            </article>
            <article className={cx("customGenerativeArtSection relative flex flex-col col-start-16 col-end-23 row-start-3 row-end-10 grid grid-cols-7 grid-rows-7 w-[var(--grid-unit-7)] h-[var(--grid-unit-7)] bg-white", contentBorderClass)}>
              <h2 className="customGenerativeArtTitle col-start-1 col-end-4 row-start-1 row-end-5 benefitTitle">
                Custom Generative Art
              </h2>
              <div className="customGenerativeArtContent col-start-1 col-end-8 row-start-5 row-end-8 benefitDescription">
                {t('sbt.benefit.customGenerativeArt1')}<br />
                {t('sbt.benefit.customGenerativeArt2')}
              </div>
              <figure className="customGenerativeArtFigure col-start-5 col-end-8 row-start-1 row-end-4 benefitFigure">
                <img className="object-contain" src={customGenerativeArt} alt="custom generative art" />
              </figure>
            </article>
          </div>
        )}
      </div>
    </section>
  )
}

function MembershipSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="membershipSection grid grid-cols-12 grid-rows-11 w-[var(--grid-unit-12)] h-[var(--grid-unit-11)]">
            <div className="col-start-9 col-end-10 row-start-1 row-end-2 bg-[#DADEFB]" />
            <h2 className="membershipTitle col-start-2 col-end-9 row-start-2 row-end-4 flex justify-center items-center font-semibold text-[#4545C9]"
                style={{ fontSize: 'max(1rem, 7.813vw)' }}>
              Membership
            </h2>
            <dl className="grid grid-cols-22 grid-rows-6 col-start-2 col-end-12 row-start-4 row-end-10">
              <dt className={cx("selectiveSbt col-start-1 col-end-12 row-start-1 row-end-3 flex justify-start items-center px-[3vw] font-semibold bg-[#EFF1FF]", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.8rem, 2.604vw, 1.25rem)' }}>
                1eg Selective SBT
              </dt>
              <dd className={cx("selectiveSbtContent col-start-12 col-end-23 row-start-1 row-end-3 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.7rem, 2.344vw, 1.125rem)' }}>
                <ul className="w-full space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>2023.Q1</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
              <dt className={cx("foundingNft col-start-1 col-end-12 row-start-3 row-end-5 flex justify-start items-center px-[3vw] font-semibold bg-[#F5F5F5]", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.8rem, 2.604vw, 1.25rem)' }}>
                Founding NFT
              </dt>
              <dd className={cx("foundingNftContent col-start-12 col-end-23 row-start-3 row-end-5 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.7rem, 2.344vw, 1.125rem)' }}>
                <ul className="w-full space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>TBD</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
              <dt className={cx("selectiveToken col-start-1 col-end-12 row-start-5 row-end-7 flex justify-start items-center px-[3vw] font-semibold bg-[#F5F5F5]", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.8rem, 2.604vw, 1.25rem)' }}>
                $ ???
              </dt>
              <dd className={cx("selectiveTokenContent col-start-12 col-end-23 row-start-5 row-end-7 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.7rem, 2.344vw, 1.125rem)' }}>
                <ul className="w-full space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>TBD</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        ) : (
          <div className="membershipSection grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <div className="col-start-11 col-end-12 row-start-1 row-end-2 bg-[#DADEFB]" />
            <h2 className={cx("membershipTitle relative col-start-3 col-end-11 row-start-2 row-end-4 flex justify-center items-center font-semibold bg-white text-[#4545C9]", contentBorderClass)}
                style={{ fontSize: 'clamp(2rem, 4.167vw, 5rem)' }}>
              Membership
            </h2>
            <dl className="grid grid-cols-20 grid-rows-6 col-start-3 col-end-23 row-start-5 row-end-11">
              <dt className={cx("selectiveSbt col-start-1 col-end-10 row-start-1 row-end-3 flex justify-start items-center pl-[3vw] font-semibold bg-[#EFF1FF]", contentBorderClass)}
                  style={{ fontSize: 'clamp(1.5rem, 2.5vw, 3rem)' }}>
                1eg Selective SBT
              </dt>
              <dd className={cx("selectiveSbtContent col-start-10 col-end-21 row-start-1 row-end-3 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(0.9rem, 1.250vw, 1.5rem)' }}>
                <ul className="w-full space-y-1 lg:space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>2023.Q1</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
              <dt className={cx("foundingNft col-start-1 col-end-10 row-start-3 row-end-5 flex justify-start items-center pl-[3vw] font-semibold bg-[#F5F5F5]", contentBorderClass)}
                  style={{ fontSize: 'clamp(1.5rem, 2.5vw,3rem)' }}>
                Founding NFT
              </dt>
              <dd className={cx("foundingNftContent col-start-10 col-end-21 row-start-3 row-end-5 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(1rem, 1.250vw, 1.5rem)' }}>
                <ul className="w-full space-y-1 lg:space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>TBD</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
              <dt className={cx("selectiveToken col-start-1 col-end-10 row-start-5 row-end-7 flex justify-start items-center pl-[3vw] font-semibold bg-[#F5F5F5]", contentBorderClass)}
                  style={{ fontSize: 'clamp(1.5rem, 2.5vw,3rem)' }}>
                $ ???
              </dt>
              <dd className={cx("selectiveTokenContent col-start-10 col-end-21 row-start-5 row-end-7 flex justify-between items-center px-[3vw] font-semibold bg-white", contentBorderClass)}
                  style={{ fontSize: 'clamp(1rem, 1.250vw, 1.5rem)' }}>
                <ul className="w-full space-y-1 lg:space-y-[1vw]">
                  <li className="flex justify-between">
                    <dt>Mint Date</dt>
                    <dd>TBD</dd>
                  </li>
                  <li className="flex justify-between">
                    <dt>Price</dt>
                    <dd>TBD</dd>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        )}
      </div>
    </section>
  )
}

function ConnectUsSection() {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMobile = !useMediaQuery('(min-width: 768px)')

  useEffect(() => {
    videoRef.current?.play().then()
  }, [])

  return (
    <section className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="grid grid-cols-12 grid-rows-7 w-screen h-[var(--grid-unit-7)] bg-[#EDF1FF]">
            <div className="col-start-1 col-end-13 row-start-1 row-end-6 flex flex-col justify-evenly py-[var(--grid-unit-1)] px-[var(--grid-unit-1)] font-semibold">
              <div className="" style={{ fontSize: 'clamp(1.5rem, 2.865vw, 3.438rem)' }}>
                Connect with Us
              </div>
              <div style={{ fontSize: 'clamp(1.2rem, 1.563vw, 1.875rem)' }}>
                info@1egdao.io
              </div>
            </div>
            <figure className="relative col-start-1 col-end-13 row-start-6 row-end-8">
              <div className="absolute inset-0">
                <img className="h-full object-cover" src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-connect-frame001.png`} alt="1egdao connect" />
              </div>
              <video ref={videoRef} className="absolute inset-0 h-full object-cover" muted loop playsInline>
                <source src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-connect.mp4`} type="video/mp4" />
              </video>
            </figure>
          </div>
        ) : (
          <div className="grid grid-cols-24 grid-rows-8 w-screen h-[var(--grid-unit-8)] bg-[#EDF1FF]">
            <div className="col-start-1 col-end-25 row-start-1 row-end-6 flex flex-col justify-evenly py-[var(--grid-unit-1)] px-[var(--grid-unit-1)] font-semibold">
              <div style={{ fontSize: 'clamp(1.5rem, 2.865vw, 3.438rem)' }}>
                Connect with Us
              </div>
              <div style={{ fontSize: 'clamp(1.2rem, 1.563vw, 1.875rem)' }}>
                info@1egdao.io
              </div>
            </div>
            <figure className="relative col-start-1 col-end-25 row-start-6 row-end-9 flex flex-col">
              <div className="absolute inset-0">
                <img className="h-full object-cover" src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-connect-frame001.png`} alt="1egdao connect" />
              </div>
              <video ref={videoRef} className="absolute inset-0 h-full object-fill" muted loop playsInline>
                <source src={`${process.env.PUBLIC_URL}/assets/1egdao-artwork-connect.mp4`} type="video/mp4" />
              </video>
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}
