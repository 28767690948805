import ArtIcon from './art.png'
import ArtistIcon from './artist.png'
import BrandIcon from './brand.png'
import BuilderIcon from './builder.png'
import CommunicatorIcon from './communicator.png'
import CommunityIcon from './community.png'
import ContentsIcon from './contents.png'
import CultureIcon from './culture.png'
import DeliveryIcon from './delivery.png'
import EcommerceIcon from './ecommerce.png'
import EconomyIcon from './economy.png'
import EducationIcon from './education.png'
import EngineerIcon from './engineer.png'
import EntertainmentIcon from './entertainment.png'
import EsgIcon from './esg.png'
import FinanceIcon from './finance.png'
import FnbIcon from './fnb.png'
import GameIcon from './game.png'
import HrIcon from './hr.png'
import InfluencerIcon from './influencer.png'
import InsuranceIcon from './insurance.png'
import ItIcon from './it.png'
import LeisureIcon from './leisure.png'
import MarketerIcon from './marketer.png'
import MedicineIcon from './medicine.png'
import MusicIcon from './music.png'
import PostIcon from './post.png'
import PostboxIcon from './postbox.png'
import PublicOrganizationIcon from './public.png'
import RealestateIcon from './realestate.png'
import ResearcherIcon from './researcher.png'
import SocietyIcon from './society.png'
import SportsIcon from './sports.png'
import StrategistIcon from './strategist.png'
import TechnologyIcon from './technology.png'
import TraderIcon from './trader.png'
import VcIcon from './vc.png'
import Web3loverIcon from './web3lover.png'

export default {
  ArtIcon,
  ArtistIcon,
  BrandIcon,
  BuilderIcon,
  CommunicatorIcon,
  CommunityIcon,
  ContentsIcon,
  CultureIcon,
  DeliveryIcon,
  EcommerceIcon,
  EconomyIcon,
  EducationIcon,
  EngineerIcon,
  EntertainmentIcon,
  EsgIcon,
  FinanceIcon,
  FnbIcon,
  GameIcon,
  HrIcon,
  InfluencerIcon,
  InsuranceIcon,
  ItIcon,
  LeisureIcon,
  MarketerIcon,
  MedicineIcon,
  MusicIcon,
  PostIcon,
  PostboxIcon,
  PublicOrganizationIcon,
  RealestateIcon,
  ResearcherIcon,
  SocietyIcon,
  SportsIcon,
  StrategistIcon,
  TechnologyIcon,
  TraderIcon,
  VcIcon,
  Web3loverIcon,
}
