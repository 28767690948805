import {useMediaQuery} from "usehooks-ts";

export default function Footer() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <footer className="bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="w-screen h-[var(--grid-unit-1)] px-[3vw] flex justify-between items-center font-regular opacity-40 text-black text-[10px]">
            <a href="https://amber-picture-92f.notion.site/1966aaee0fcd4005a077cc38ccd2ef05" target="_blank" rel="noopener noreferrer">
              개인정보 처리방침
            </a>
            <div>
              © 2022 1egDAO, All rights reserved.
            </div>
          </div>
        ) : (
          <div
            className="w-screen h-[var(--grid-unit-2)] px-[var(--grid-unit-1)] flex justify-between items-center font-regular opacity-40 text-black"
            style={{ fontSize: 'clamp(1rem, 1.302vw, 1.563rem)' }}
          >
            <a href="https://amber-picture-92f.notion.site/1966aaee0fcd4005a077cc38ccd2ef05" target="_blank" rel="noopener noreferrer">
              개인정보 처리방침
            </a>
            <div>
              © 2022 1egDAO, All rights reserved.
            </div>
          </div>
        )}
      </div>
    </footer>
  )
}
