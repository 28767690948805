import cx from 'classnames'
import potentialIllustration from './potential-illustration.png'
import localBasedIllustration from './local-based-illustration.png'
import {
  contentBorderClass,
  contentBorderExcludeBottomClass,
} from "@src/Styles"
import {useEffect} from "react"
import config, {fadeInOptions} from '@src/config'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useMediaQuery} from "usehooks-ts"
import stair from './stair.png'
import {useTranslation} from "react-i18next";

let once

export default function CommunityPage() {
  useEffect(() => {
    if (once) {
      return
    }

    once = true

    ScrollTrigger.create({
      trigger: '.communityPage .communityTitle',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.communityPage .communityTitle', fadeInOptions)
    })

    ScrollTrigger.create({
      trigger: '.communityPage .prologue',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.communityPage .prologue', fadeInOptions)
    })

    ScrollTrigger.create({
      trigger: '.communityPage div.selectingProcess',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.communityPage .selectingProcessTitle', fadeInOptions)
        .from('.communityPage .selectingProcessDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.communityPage .selectingProcessFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious),
    })

    ScrollTrigger.create({
      trigger: '.communityPage .potential',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.communityPage .potentialTitle', fadeInOptions)
        .from('.communityPage .potentialDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.communityPage .potentialFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious),
    })

    ScrollTrigger.create({
      trigger: '.communityPage .localBased',
      start: config.effects.fadeIn.start,
      animation: gsap.timeline()
        .from('.communityPage .localBasedTitle', fadeInOptions)
        .from('.communityPage .localBasedDescription', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious)
        .from('.communityPage .localBasedFigure', fadeInOptions, config.effects.fadeIn.pastStartOfThePrevious),
    })

  }, [])

  return (
    <div className="communityPage relative">
      <CommunityTitleSection />
      <CommunityPrologueSection />
      <SelectingProcessSection />
      <PotentialSection />
      <LocallyBasedSection />
    </div>
  )
}

function CommunityTitleSection() {
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section id="community" className="relative" style={{ backgroundColor: '#4545C9' }}>
      <div className="bgGridY">
        <div className="bgGridX">
          {isMobile ? (
            <div className="grid grid-cols-12 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)]">
              <div className="col-start-2 col-end-3 row-start-5 row-end-6 bg-white" />
              <h1 className="communityTitle col-start-3 col-end-20 row-start-7 row-end-13 pl-2 flex flex-col justify-start font-semibold text-white"
                  style={{ fontSize: 'clamp(1.8rem, 7vw, 3.75rem)' }}>
                We,<br />
                the Community,<br />
                connect<br />
                Web3 and Web2.
              </h1>
            </div>
          ) : (
            <div className="relative px-[var(--grid-unit-2)] pt-[var(--grid-unit-4)] pb-[var(--grid-unit-2)]">
              <div className="grid grid-cols-20 grid-rows-9 w-[var(--grid-unit-20)] h-[var(--grid-unit-9)]">
                <div className="col-start-1 col-end-2 row-start-1 row-end-2 bg-white" />
                <h1 className="communityTitle col-start-1 col-end-20 row-start-2 row-end-10 pl-6 flex flex-col justify-center font-semibold text-white"
                    style={{ fontSize: 'var(--section-title-font-size)' }}>
                  We,<br />
                  the Community,<br />
                  connect Web3 and Web2.
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

function CommunityPrologueSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="relative bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="prologue grid grid-cols-12 grid-rows-18 w-screen h-[var(--grid-unit-18)]">
            <div className="col-start-2 col-end-3 row-start-5 row-end-6 bg-[#DADEFB]" />
            <div className="col-start-11 col-end-12 row-start-14 row-end-15 bg-[#DADEFB]" />
            <div className="col-start-3 col-end-11 row-start-7 row-end-17 flex flex-col justify-start font-semibold"
                 style={{ fontSize: 'clamp(1.1rem, 5.208vw, 2.5rem)' }}>
              <div className="flex items-center leading-relaxed">
                {t('community.prologue1')}<br />
                {t('community.prologue2')}
              </div>
              <div className="flex items-center mt-[var(--grid-unit-1)] leading-relaxed">
                {t('community.prologue3')}<br />
                {t('community.prologue4')}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-20 grid-rows-9 h-[var(--grid-unit-12)] w-full px-[var(--grid-unit-2)] pt-[var(--grid-unit-2)] pb-[var(--grid-unit-1)]">
            <div className="prologue col-start-1 col-end-21 row-start-1 row-end-10 flex flex-col justify-evenly pl-6 font-bold"
                 style={{ fontSize: 'clamp(2rem, 3.333vw, 4rem)' }}>
              <div>
                {t('community.prologue1')}<br />
                {t('community.prologue2')}
              </div>
              <div>
                {t('community.prologue3')}<br />
                {t('community.prologue4')}
              </div>
            </div>
            <div className="col-start-20 col-end-21 row-start-9 row-end-10 bg-[#9D9DEB]" />
          </div>
        )}
      </div>
    </section>
  )
}

function SelectingProcessSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="relative bgGridY">
      <div className="bgGridX flex items-center">
        {isMobile ? (
          <div className="selectingProcess relative grid grid-cols-10 grid-rows-18 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)] px-[var(--grid-unit-1)]">
            <div className="col-start-10 col-end-11 row-start-2 row-end-3 bg-[#4545C9]" />
            <h2 className={cx("selectingProcessTitle relative col-start-1 col-end-10 row-start-3 row-end-5 flex justify-start items-center pl-[var(--grid-unit-1)] font-semibold bg-white text-[#4545C9]", contentBorderExcludeBottomClass)}
                style={{ fontSize: 'clamp(1.3rem, 5.208vw, 3.75rem)' }}>
              #Selection Process
            </h2>
            <div className={cx("selectingProcessDescription relative col-start-1 col-end-10 row-start-5 row-end-16 bg-white", contentBorderClass)}>
              <div className="flex flex-col justify-start w-[var(--grid-unit-8)] h-[var(--grid-unit-11)] pt-[4.167vw] px-[var(--grid-unit-1)]">
                <div className="titleParagraph">
                  {t('community.selecting1')}<br />
                  {t('community.selecting2')}
                </div>
                <div className="descriptionParagraph">
                  {t('community.selecting3')}<br />
                  {t('community.selecting4')}
                </div>
              </div>
            </div>
            <figure className="selectingProcessFigure col-start-6 col-end-12 row-start-11 row-end-17 flex justify-center items-center z-10">
              <img className="h-full object-contain" src={stair} alt="selecting process illustration" />
            </figure>
          </div>
        ) : (
          <div className="selectingProcess relative grid grid-cols-24 grid-rows-12 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)]">
            <h2 className={cx("selectingProcessTitle relative pl-[var(--grid-unit-1)] col-start-3 col-end-13 row-start-3 row-end-5 flex items-center font-semibold bg-white", contentBorderExcludeBottomClass)}
                style={{ color: '#4545C9', fontSize: 'clamp(1.2rem, 3.125vw, 3.75rem)' }}>
              #Selection Process
            </h2>
            <div className={cx("selectingProcessDescription col-start-3 col-end-13 row-start-5 row-end-10 flex flex-col justify-start pt-[2.083vw] px-[var(--grid-unit-1)] bg-white", contentBorderClass)}>
              <div className="titleParagraph">
                {t('community.selecting1')}<br />
                {t('community.selecting2')}
              </div>
              <div className="descriptionParagraph">
                {t('community.selecting3')}<br />
                {t('community.selecting4')}
              </div>
            </div>
            <div className="col-start-13 col-end-14 row-start-10 row-end-11 bg-[#4545C9]" />
            <figure className="selectingProcessFigure col-start-16 col-end-22 row-start-3 row-end-10 flex justify-center items-center">
              <img className="h-full object-cover" src={stair} alt="selecting process illustration" />
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}

function PotentialSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="relative bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="potential relative grid grid-cols-10 grid-rows-16 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)] p-[var(--grid-unit-1)]">
            <div className="col-start-1 col-end-2 row-start-1 row-end-2 bg-[#9898DA]" />
            <h2 className={cx("potentialTitle relative col-start-2 col-end-11 row-start-2 row-end-4 flex justify-end items-center pr-[var(--grid-unit-1)] font-semibold bg-white text-[#4545C9]", contentBorderExcludeBottomClass)}
                style={{ fontSize: 'clamp(1.3rem, 5.208vw, 2.5rem)' }}>
              #Potential
            </h2>
            <div className={cx("potentialDescription relative col-start-2 col-end-11 row-start-4 row-end-15 pl-[var(--grid-unit-1)] text-end bg-white", contentBorderClass)}>
              <div className="flex flex-col justify-start items-end w-full h-full pt-[4.167vw] px-[var(--grid-unit-1)]">
                <div className="titleParagraph">
                  {t('community.potential.title1')}<br />
                  {t('community.potential.title2')}
                </div>
                <div className="descriptionParagraph">
                  {t('community.potential.desc1')}<br />
                  {t('community.potential.desc2')}
                </div>
                <div className="descriptionParagraph">
                  {t('community.potential.desc3')}<br />
                  {t('community.potential.desc4')}
                </div>
              </div>
            </div>
            <figure className="potentialFigure col-start-1 col-end-7 row-start-11 row-end-17 flex justify-center items-center z-10">
              <img className="h-full object-contain" src={potentialIllustration} alt="potential illustration" />
            </figure>
          </div>
        ) : (
          <div className="potential relative grid grid-cols-20 grid-rows-10 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)] px-[var(--grid-unit-2)] py-[var(--grid-unit-1)]">
            <h2 className={cx("potentialTitle relative col-start-12 col-end-20 row-start-2 row-end-4 flex items-center pl-[var(--grid-unit-1)] font-semibold bg-white text-[#4545C9]", contentBorderExcludeBottomClass)}
                style={{ fontSize: 'clamp(1.2rem, 3.125vw, 3.75rem)' }}>
              #Potential
            </h2>
            <div className={cx("potentialDescription relative col-start-12 col-end-20 row-start-4 row-end-11 flex flex-col justify-start pt-[2.083vw] px-[var(--grid-unit-1)] bg-white", contentBorderClass)}>
              <div className="titleParagraph">
                {t('community.potential.title1')}<br />
                {t('community.potential.title2')}
              </div>
              <div className="descriptionParagraph">
                {t('community.potential.desc1')}<br />
                {t('community.potential.desc2')}
              </div>
              <div className="descriptionParagraph">
                {t('community.potential.desc3')}<br />
                {t('community.potential.desc4')}
              </div>
            </div>
            <div className="col-start-20 col-end-21 row-start-1 row-end-2 bg-[#9D9DEB]" />
            <figure className="potentialFigure col-start-2 col-end-10 row-start-3 row-end-10 flex justify-center items-center">
              <img className="h-full object-contain" src={potentialIllustration} alt="potential illustration" />
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}

function LocallyBasedSection() {
  const { t } = useTranslation()
  const isMobile = !useMediaQuery('(min-width: 768px)')

  return (
    <section className="relative bgGridY">
      <div className="bgGridX">
        {isMobile ? (
          <div className="localBased relative grid grid-cols-10 grid-rows-16 w-[var(--grid-unit-12)] h-[var(--grid-unit-18)] p-[var(--grid-unit-1)]">
            <h2 className={cx("localBasedTitle relative col-start-1 col-end-10 row-start-2 row-end-4 flex justify-start items-center pl-[var(--grid-unit-1)] font-semibold bg-white text-[#4545C9]", contentBorderExcludeBottomClass)}
                style={{ fontSize: 'clamp(1.2rem, 5.208vw, 2.5rem)' }}>
              #Locally Based
            </h2>
            <div className={cx("localBasedDescription relative col-start-1 col-end-10 row-start-4 row-end-15 pt-[4.167vw] px-[var(--grid-unit-1)] flex flex-col justify-start bg-white", contentBorderClass)}>
              <div className="titleParagraph">
                {t('community.locallybased.title1')}<br />
                {t('community.locallybased.title2')}
              </div>
              <div className="descriptionParagraph">
                {t('community.locallybased.desc1')}
              </div>
              <div className="descriptionParagraph">
                {t('community.locallybased.desc2')}
              </div>
            </div>
            <div className="col-start-10 col-end-11 row-start-1 row-end-2 bg-[#EFF1FF]" />
            <figure className="localBasedFigure col-start-5 col-end-11 row-start-11 row-end-17 flex justify-center items-center z-10">
              <img className="h-full object-contain" src={localBasedIllustration} alt="local-based illustration" />
            </figure>
          </div>
        ) : (
          <div className="localBased relative grid grid-cols-20 grid-rows-10 w-[var(--grid-unit-24)] h-[var(--grid-unit-12)] px-[var(--grid-unit-2)] py-[var(--grid-unit-1)]">
            <h2 className={cx("localBasedTitle relative pl-[var(--grid-unit-1)] col-start-1 col-end-11 row-start-2 row-end-4 flex justify-start items-center pl-[var(--grid-unit-1)] font-semibold bg-white text-[#4545C9]", contentBorderExcludeBottomClass)}
                style={{ fontSize: 'clamp(1.2rem, 3.125vw, 3.75rem)' }}>
              #Locally Based
            </h2>
            <div className={cx("localBasedDescription col-start-1 col-end-11 row-start-4 row-end-11 flex flex-col justify-start pt-[2.083vw] px-[var(--grid-unit-1)] bg-white", contentBorderClass)}>
              <div className="titleParagraph">
                {t('community.locallybased.title1')}<br />
                {t('community.locallybased.title2')}
              </div>
              <div className="descriptionParagraph">
                {t('community.locallybased.desc1')}
              </div>
              <div className="descriptionParagraph">
                {t('community.locallybased.desc2')}
              </div>
            </div>
            <div className="col-start-11 col-end-12 row-start-1 row-end-2 bg-[#E1E1FD]" />
            <figure className="localBasedFigure col-start-13 col-end-20 row-start-2 row-end-11 flex justify-center items-center">
              <img className="h-full object-contain" src={localBasedIllustration} alt="local-based illustration" />
            </figure>
          </div>
        )}
      </div>
    </section>
  )
}
