import wagmiClient from '@src/wagmiClient'
import { ConnectKitProvider, SIWEProvider } from 'connectkit'
import { RouterProvider } from 'react-router-dom'
import router from '@src/Router'
import { WagmiConfig } from 'wagmi'
import React from 'react'
import axiosClient from '@src/client/axiosClient'
import { SiweMessage } from 'siwe'
import { observer } from 'mobx-react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import './App.css'


function App() {
  const { userStore } = useRootStore()

  const siweConfig = {
    getNonce: async () => {
      const res = await axiosClient.get('/login/siwe/nonce')
      return res.data
    },
    createMessage: ({ nonce, address, chainId }) => {
      return new SiweMessage({
        version: '1',
        domain: window.location.host,
        uri: window.location.origin,
        address,
        chainId,
        nonce,
        statement: `Sign in 1egdao.io with Ethereum!`
      }).prepareMessage()
    },
    verifyMessage: async ({ message, signature }) => {
      const res = await axiosClient.post('/login/siwe/verify', {
        message,
        signature,
      })
      return true
    },
    getSession: async () => {
      const res = await axiosClient.get('/login/siwe/session')
      const { address, chainId } = res.data.data

      if (address && chainId) {
        userStore.setSignedIn(true)
      } else {
        userStore.setSignedIn(false)
      }

      return {
        address,
        chainId,
      }
    },
    signOut: async () => {
      try {
        await axiosClient.post('/login/siwe/logout')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  }

  return (
    <WagmiConfig client={wagmiClient}>
      <SIWEProvider {...siweConfig}>
        <ConnectKitProvider>
          <RouterProvider router={router}/>
        </ConnectKitProvider>
      </SIWEProvider>
    </WagmiConfig>
  )
}

export default observer(App)
