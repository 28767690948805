import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useRootStore } from '@src/stores/RootStoreProvider'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid'
import ApplicationNavigator from '@src/pages/apply/season-1-application/ApplicationNavigator'
import { ErrorMessage } from '@hookform/error-message'
import { contentBorderClass } from '@src/Styles'


const labelInputWrapperClass = 'relative grid grid-cols-3 items-start gap-4 flex justify-center'
const labelCommon = 'self-center text-[24px] font-bold'
const requiredDecoration = "after:content-['*'] after:text-[#4545C9]"
const inputStyle = 'self-center text-center block text-[20px] h-full py-6 border border-[#CCCCEC] focus:border-indigo-500 focus:ring-indigo-500'
const inputClass = `col-span-2 ${inputStyle}`
const inputHalfClass = `col-span-1 ${inputStyle}`
const communities = [
  'Alphacrewz',
  'Aptos',
  'Arbitrum',
  'Azuki',
  'BAYC',
  'BitDAO',
  'BugCity',
  'Clone X',
  'DADAZ',
  'Doodles',
  'FWB',
  'Hello bot',
  'Hide me, please',
  'Ludium',
  'Maker DAO',
  'Moonbirds',
  'Near Protocol',
  'Polygon',
  'RSV',
  'Solana',
  'Trouble Punk',
  'Voiceverse',
  'Wake Up bored',
]

interface Web3CommunityExperience {
  communityOption: string
  customCommunity: string | undefined
  role: string
}

const emptyWeb3CommunityExperience: Web3CommunityExperience = {
  communityOption: '커뮤니티 검색',
  customCommunity: undefined,
  role: '',
}

const ApplicationSection1 = () => {
  const navigate = useNavigate()
  const { applicationStore } = useRootStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  const [isWeb3CommunityExperienced, setIsWeb3CommunityExperienced] = useState<boolean | null>(null)
  const [isSubmitClickedAtLeastOnce, setIsSubmitClickedAtLeastOnce] = useState<boolean>(false)
  const [web3CommunityExperiences, setWeb3CommunityExperiences] = useState<Web3CommunityExperience[]>([])
  const [isPrivacyForRecruitAgreed, setIsPrivacyForRecruitAgreed] = useState<boolean | null>(null)
  const [profileForRecruitGender, setProfileForRecruitGender] = useState<string>('')

  const currentSection = searchParams.get('section') || 1

  useEffect(() => {
    setValue('nickname', applicationStore.nickname)
    setValue('email', applicationStore.email)
    setValue('twitter', applicationStore.twitter)
    setValue('instagram', applicationStore.instagram)
    setValue('blogPlatform', applicationStore.blogPlatform)
    setValue('blog', applicationStore.blog)
    setValue('job', applicationStore.job)

    if (applicationStore.section1UpdatedAt) {
      setIsWeb3CommunityExperienced(applicationStore.web3CommunityExperiences.length > 0)
    }
    setWeb3CommunityExperiences(applicationStore.web3CommunityExperiences.map(experience => ({
      communityOption: experience.community ? (communities.includes(experience.community) ? experience.community : '직접입력') : '커뮤니티 검색',
      customCommunity: experience.community ? (communities.includes(experience.community) ? experience.community : undefined) : undefined,
      role: experience.role,
    })))

    setIsPrivacyForRecruitAgreed(applicationStore.isRecruitAgreed)
    if (applicationStore.isRecruitAgreed) {
      setValue('profileForRecruit.name', applicationStore.profileForRecruit.name)
      setValue('profileForRecruit.birth', applicationStore.profileForRecruit.birth)
      setProfileForRecruitGender(applicationStore.profileForRecruit.gender)
      setValue('profileForRecruit.phone', applicationStore.profileForRecruit.phone)
    }
  }, [])

  const onSubmit = data => {
    setIsSubmitClickedAtLeastOnce(true)

    if (isPrivacyForRecruitAgreed === null || isPrivacyForRecruitAgreed === undefined) {
      return
    }

    applicationStore.nickname = data.nickname
    applicationStore.email = data.email
    applicationStore.twitter = data.twitter
    applicationStore.instagram = data.instagram
    applicationStore.blogPlatform = data.blogPlatform
    applicationStore.blog = data.blog
    applicationStore.job = data.job
    applicationStore.web3CommunityExperiences = web3CommunityExperiences.map(experience => ({
      community: experience.customCommunity ? experience.customCommunity : experience.communityOption,
      role: experience.role,
    }))

    applicationStore.isRecruitAgreed = data.profileForRecruit && data.profileForRecruit.agree
    if (applicationStore.isRecruitAgreed) {
      applicationStore.profileForRecruit = {
        name: data.profileForRecruit.name,
        birth: data.profileForRecruit.birth,
        gender: data.profileForRecruit.gender,
        phone: data.profileForRecruit.phone,
      }
    }
    applicationStore.section1UpdatedAt = Date.now()

    navigate({
      pathname: '/apply/season-1-application',
      search: '?section=2',
    })
    window.scrollTo(0, 0)
  }

  const onError = error => {
    setIsSubmitClickedAtLeastOnce(true)
    console.warn(error)
  }

  return (
    <div className="relative bgGridY">
      <div className="bgGridX w-screen min-h-screen px-[var(--grid-unit-1)] py-[var(--grid-unit-1)]">
        <nav className="h-[var(--grid-unit-1)] w-[var(--grid-unit-15)]">
          <ApplicationNavigator curSectionNum={currentSection} />
        </nav>
        <section className={classNames("w-full bg-white", contentBorderClass)}>
          <form className="relative space-y-12 p-[80px] mx-auto min-w-[600px] max-w-[1200px] text-[30px] flex flex-col justify-center"
                onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="self-end text-[20px] text-black">
              <span className="text-[#4545C9]">*</span>필수입력사항
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="nickname" className={classNames(labelCommon, requiredDecoration)}>
                닉네임
              </label>
              <input
                type="text"
                className={inputClass}
                placeholder="1egDAO에서 불리고 싶은 닉네임"
                autoComplete="off"
                {...register('nickname', { required: '닉네임을 입력해야 합니다' })}
              />
              <ValidateErrorMessage additionalClassName="col-start-2 col-span-full" errors={errors} name="nickname" />
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="email" className={classNames(labelCommon, requiredDecoration)}>
                E-mail
              </label>
              <input
                type="email"
                className={inputClass}
                placeholder="접수 이후 결과 및 공지 안내를 위한 이메일"
                {...register('email', { required: '이메일을 입력해 주세요' })}
              />
              <ValidateErrorMessage additionalClassName="col-start-2 col-span-full" errors={errors} name="email" />
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="twitter" className={classNames(labelCommon, requiredDecoration)}>
                Twitter
              </label>
              <input
                type="text"
                className={inputClass}
                placeholder="트위터 핸들 (예.@roypa_eth)"
                autoComplete="off"
                {...register('twitter', { required: '트위터 핸들을 입력해 주세요' })}
              />
              <ValidateErrorMessage additionalClassName="col-start-2 col-span-full" errors={errors} name="twitter" />
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="instagram" className={classNames(labelCommon)}>
                Instagram
              </label>
              <input
                type="text"
                className={inputClass}
                placeholder="인스타그램 아이디 (예. @welikethedoodles)"
                autoComplete="off"
                {...register('instagram')}
              />
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="blog" className={classNames(labelCommon)}>
                Blog
              </label>
              <input
                type="text"
                className={inputHalfClass}
                placeholder="플랫폼명 (예. 미디엄, 브런치)"
                autoComplete="off"
                {...register('blogPlatform')}
              />
              <input
                type="text"
                className={inputHalfClass}
                placeholder="블로그 링크"
                autoComplete="off"
                {...register('blog')}
              />
            </div>

            <div className={labelInputWrapperClass}>
              <label htmlFor="job" className={classNames(labelCommon, requiredDecoration)}>
                현재 종사하는 직업
              </label>
              <input
                type="text"
                className={inputClass}
                placeholder="예. 금융업 종사, 아티스트, 프리랜서 개발자, 대학생/대학원생, 취준생 등"
                autoComplete="off"
                {...register('job', { required: '직업을 입력해야 합니다' })}
              />
              <ValidateErrorMessage additionalClassName="col-start-2 col-span-full" errors={errors} name="job" />
            </div>

            <div className={labelInputWrapperClass}>
              <p className={classNames(labelCommon, requiredDecoration)}>
                웹3 커뮤니티 경험
              </p>
              <RadioInput
                name="experience"
                options={[{ label: '있음', value: true }, { label: '없음', value: false }]}
                value={isWeb3CommunityExperienced}
                onChange={value => {
                  if (value) {
                    setIsWeb3CommunityExperienced(true)
                    if (!web3CommunityExperiences.length) {
                      setWeb3CommunityExperiences([{...emptyWeb3CommunityExperience}])
                    }
                  } else {
                    setIsWeb3CommunityExperienced(false)
                    setWeb3CommunityExperiences([])
                  }
                }}
              />
              {isSubmitClickedAtLeastOnce && isWeb3CommunityExperienced === null && (
                <ErrorMessageComponent message="웹3 커뮤니티 경험에 대해 선택해야 합니다" />
              )}
              {web3CommunityExperiences.map((experience, index) => {
                return (
                  <div key={index} className="relative col-start-1 col-span-full grid grid-cols-3 items-start gap-4">
                    <CommunitySelect
                      additionalClassName="col-start-2 col-span-1"
                      communityOption={experience.communityOption}
                      onCommunityOptionChange={option => {
                        web3CommunityExperiences[index].communityOption = option
                        if (option === '직접입력') {
                          web3CommunityExperiences[index].customCommunity = web3CommunityExperiences[index].customCommunity ?? ''
                        } else {
                          web3CommunityExperiences[index].customCommunity = undefined
                        }
                        setWeb3CommunityExperiences([...web3CommunityExperiences])  // trigger rendering
                      }}
                    />
                    {experience.communityOption === '직접입력' ? (
                      <>
                        <input
                          type="text"
                          className={classNames("col-start-3 col-span-1", inputStyle)}
                          placeholder="커뮤니티 이름"
                          autoComplete="off"
                          defaultValue={web3CommunityExperiences[index].customCommunity ? web3CommunityExperiences[index].customCommunity : ''}
                          onChange={event => {
                            web3CommunityExperiences[index].customCommunity = event.target.value
                            setWeb3CommunityExperiences([...web3CommunityExperiences])  // trigger rendering
                          }}
                        />
                        <input
                          type="text"
                          className={classNames("col-start-2 col-span-2", inputStyle)}
                          placeholder="세부 역할 (예. 홀더, CM)"
                          autoComplete="off"
                          defaultValue={web3CommunityExperiences[index].role}
                          onChange={event => {
                            web3CommunityExperiences[index].role = event.target.value
                            setWeb3CommunityExperiences([...web3CommunityExperiences])  // trigger rendering
                          }}
                        />
                      </>
                    ) : (
                      <input
                        type="text"
                        className={classNames("col-start-3 col-span-1", inputStyle)}
                        placeholder="세부 역할 (예. 홀더, CM)"
                        autoComplete="off"
                        defaultValue={web3CommunityExperiences[index].role}
                        onChange={event => {
                          web3CommunityExperiences[index].role = event.target.value
                          setWeb3CommunityExperiences([...web3CommunityExperiences])  // trigger rendering
                        }}
                      />
                    )}
                    {web3CommunityExperiences.length - 1 === index ? (
                      <>
                        <div className="absolute inset-y-0 my-auto right-[-50px] flex justify-center w-[40px] h-[40px] text-[#CCCCEC] cursor-pointer transition-all duration-100 hover:text-[#4545C9]"
                             onClick={() => {
                               web3CommunityExperiences.push({...emptyWeb3CommunityExperience})
                               setWeb3CommunityExperiences([...web3CommunityExperiences])  // trigger rendering
                             }}>
                          <PlusCircleIcon />
                        </div>
                        {web3CommunityExperiences.length !== 1 && (
                          <div className="absolute inset-y-0 my-auto right-[-90px] flex justify-center w-[40px] h-[40px] text-[#CCCCEC] cursor-pointer transition-all duration-100 hover:text-[#4545C9]"
                               onClick={() => {
                                 setWeb3CommunityExperiences(web3CommunityExperiences.filter((_, _index) => {
                                   return index !== _index
                                 }))
                               }}>
                            <MinusCircleIcon />
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="absolute inset-y-0 my-auto right-[-50px] flex justify-center w-[40px] h-[40px] text-[#CCCCEC] cursor-pointer transition-all duration-100 hover:text-[#4545C9]"
                           onClick={() => {
                             setWeb3CommunityExperiences(web3CommunityExperiences.filter((_, _index) => {
                               return index !== _index
                             }))
                           }}>
                        <MinusCircleIcon />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>

            <div className="bg-black h-[2px]" />

            <div className={labelInputWrapperClass}>
              <label htmlFor="privacyForRecruitAgreed" className={classNames(labelCommon, requiredDecoration, 'relative')}>
                웹3 채용 서비스 연계<br/>
                : 개인정보 제3자 제공 동의
                <a href="/privacy2.html" target="_blank" rel="noopener noreferrer"
                   className="absolute left-0 -bottom-12 text-[20px] text-[#CCCCCC] underline underline-offset-4">
                  내용 보기
                </a>
              </label>
              <RadioInput
                name="privacyForRecruitAgreed"
                options={[{ label: '동의 (기본 정보 입력)', value: true }, { label: '동의 안함', value: false }]}
                value={isPrivacyForRecruitAgreed}
                onChange={value => {
                  setIsPrivacyForRecruitAgreed(value)
                  setValue('profileForRecruit.agree', value)
                }}
              />
              {isSubmitClickedAtLeastOnce && (isPrivacyForRecruitAgreed === null || isPrivacyForRecruitAgreed === undefined) && (
                <ErrorMessageComponent additionalClassName="col-start-2 col-span-full"  message="채용 서비스 연계 정보 활용 동의를 입력해야 합니다" />
              )}
              <p className="col-start-2 col-span-2 text-[16px]" style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                제공 동의 여부는 1egDAO 지원 과정 결과에 영향을 미치지 않습니다.<br />
                위 항목에 동의 시 추후 1egDAO의 채용 서비스와 연동되어, 다양한 웹3 채용 기회와 연결될 수 있습니다.
              </p>
              {isPrivacyForRecruitAgreed && ((() => {
                const common = 'min-h-[80px] col-start-1 col-span-full grid grid-cols-6 items-start gap-4'
                return (
                  <>
                    <div className={classNames(common)}>
                      <label htmlFor="profileForRecruit.name" className={classNames('col-start-3', labelCommon, requiredDecoration)}>
                        이름
                      </label>
                      <input
                        type="text"
                        className={classNames('col-start-4 col-span-full', inputStyle)}
                        placeholder="이름을 입력해 주세요"
                        autoComplete="off"
                        {...register('profileForRecruit.name', { required: '이름을 입력해 주세요' })}
                      />
                      <ValidateErrorMessage additionalClassName="col-start-3 col-span-full" errors={errors} name="profileForRecruit.name" />
                    </div>
                    <div className={classNames(common)}>
                      <label htmlFor="profileForRecruit.birth" className={classNames('col-start-3', labelCommon, requiredDecoration)}>
                        생년월일
                      </label>
                      <input
                        type="text"
                        className={classNames('col-start-4 col-span-full', inputStyle)}
                        placeholder="YYYY   /   MM   /    DD"
                        autoComplete="off"
                        {...register('profileForRecruit.birth', { required: '생년월일을 입력해 주세요' })}
                      />
                      <ValidateErrorMessage additionalClassName="col-start-3 col-span-full" errors={errors} name="profileForRecruit.birth" />
                    </div>
                    <div className={classNames(common)}>
                      <label htmlFor="profileForRecruit.gender" className={classNames('col-start-3', labelCommon, requiredDecoration)}>
                        성별
                      </label>
                      <RadioInput
                        name="profileForRecruit.gender"
                        options={[{ label: '남자', value: 'male' }, { label: '여자', value: 'female' }]}
                        value={profileForRecruitGender}
                        onChange={value => {
                          setProfileForRecruitGender(value)
                          setValue('profileForRecruit.gender', value)
                        }}
                      />
                      {isSubmitClickedAtLeastOnce && isPrivacyForRecruitAgreed && !profileForRecruitGender && (
                        <ErrorMessageComponent additionalClassName="col-start-3 col-span-full" message="성별을 입력해야 합니다" />
                      )}
                    </div>
                    <div className={classNames(common)}>
                      <label htmlFor="profileForRecruit.phone" className={classNames('col-start-3', labelCommon, requiredDecoration)}>
                        휴대폰
                      </label>
                      <input
                        type="text"
                        className={classNames('col-start-4 col-span-full', inputStyle)}
                        placeholder="숫자만 입력해 주세요"
                        autoComplete="off"
                        {...register('profileForRecruit.phone', { required: '휴대폰 번호를 입력해 주세요' })}
                      />
                      <ValidateErrorMessage additionalClassName="col-start-3 col-span-full" errors={errors} name="profileForRecruit.phone" />
                    </div>
                  </>
                )
              })())}
            </div>

            <button
              type="submit"
              className={classNames("self-center w-[600px] h-[80px] text-[30px] text-white bg-[#4545C9] rounded-[40px] font-bold flex justify-center items-center")}>
              다 음
            </button>
          </form>
        </section>
      </div>
    </div>
  )
}

function CommunitySelect({ additionalClassName, communityOption, onCommunityOptionChange }) {
  return (
    <select className={classNames("self-center text-center block text-[20px] h-full py-6 border border-[#CCCCEC] focus:border-indigo-500 focus:ring-indigo-500", additionalClassName)}
            onChange={event => {
              onCommunityOptionChange(event.target.value)
            }}
            value={communityOption}>
      <option>커뮤니티 검색</option>
      {communities.map(community => (
        <option key={community}>{community}</option>
      ))}
      <option>직접입력</option>
    </select>
  )
}

interface RadioOptions {
  label: string
  value: any
}

function RadioInput({ name, options, value, onChange }: { name: string, options: RadioOptions[], value?: any, onChange: (any) => void }) {
  return (
    <>
      {options.map(option => (
        <label key={option.value} className="self-center flex items-center text-[20px]">
          <input
            type="radio"
            value={value === null ? undefined : value}
            className="scale-[1.7] accent-[#4545C9] mx-3 cursor-pointer"
            name={name}
            onChange={() => onChange(option.value)}
          />
          {option.label}
        </label>
      ))}
    </>
  )
}

function ValidateErrorMessage({ errors, name, additionalClassName }) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <ErrorMessageComponent additionalClassName={additionalClassName} message={message} /> }
    />
  )
}

function ErrorMessageComponent({ additionalClassName, message }: { additionalClassName?: string, message: string }) {
  return <p className={classNames("flex justify-center text-[20px] text-[#FF4D4D]", additionalClassName)}>{message}</p>
}

export default observer(ApplicationSection1)
