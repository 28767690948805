import { makeAutoObservable, toJS } from 'mobx'
import { RootStore } from '@src/stores/RootStore'


export class ApplicationStore {
  nickname = ''
  email = ''
  twitter = ''
  instagram = ''
  blogPlatform = ''
  blog = ''
  job = ''
  web3CommunityExperiences: Web3CommunityExperience[] = []
  isRecruitAgreed = false
  profileForRecruit: ProfileForRecruit = {
    name: '',
    birth: '',
    gender: '',
    phone: '',
  }
  web3Perspective: string = ''
  web3Ability: string = ''
  web3Industries: string[] = []

  q1Introduce = ''
  q2Opinion = ''
  q3OneSentence = ''
  q4Reason = ''
  q5Ability = ''
  q6HowToConnect = ''
  q7Anything = ''
  q8Portfolio = ''

  ogQ1Impression = ''
  ogQ2Ability = ''
  ogQ3HowToConnect = ''
  ogQ4Vision = ''
  ogQ5Anything = ''
  ogQ6Portfolio = ''

  invitationCode = ''

  privacyAgree = false
  privacyProvideAgree = false
  marketingAgree = false

  section1UpdatedAt: number | undefined
  rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)

    this.rootStore = rootStore
  }

  getRequestForm() {
    return {
      invitationCode: this.invitationCode,
      email: this.email,
      nickname: this.nickname,
      twitter: this.twitter,
      instagram: this.instagram,
      blog: this.blog,
      job: this.job,
      web3Experiences: toJS(this.web3CommunityExperiences),
      profile: toJS(this.profileForRecruit),
      web3Perspective: this.web3Perspective,
      web3Ability: this.web3Ability,
      web3Industries: toJS(this.web3Industries),
      q1Introduce: this.q1Introduce,
      q2Opinion: this.q2Opinion,
      q3OneSentence: this.q3OneSentence,
      q4Reason: this.q4Reason,
      q5Ability: this.q5Ability,
      q6HowToConnect: this.q6HowToConnect,
      q7Anything: this.q7Anything,
      q8Portfolio: this.q8Portfolio,
      privacyAgree: this.privacyAgree,
      privacyProvideAgree: this.privacyProvideAgree,
      marketingAgree: this.marketingAgree,
    }
  }

  getRequestOgForm() {
    return {
      invitationCode: this.invitationCode,
      email: this.email,
      nickname: this.nickname,
      twitter: this.twitter,
      instagram: this.instagram,
      blog: this.blog,
      job: this.job,
      web3Experiences: toJS(this.web3CommunityExperiences),
      profile: toJS(this.profileForRecruit),
      web3Perspective: this.web3Perspective,
      web3Ability: this.web3Ability,
      web3Industries: toJS(this.web3Industries),
      ogQ1Impression: this.ogQ1Impression,
      ogQ2Ability: this.ogQ2Ability,
      ogQ3HowToConnect: this.ogQ3HowToConnect,
      ogQ4Vision: this.ogQ4Vision,
      ogQ5Anything: this.ogQ5Anything,
      ogQ6Portfolio: this.ogQ6Portfolio,
      privacyAgree: this.privacyAgree,
      privacyProvideAgree: this.privacyProvideAgree,
      marketingAgree: this.marketingAgree,
    }
  }
}

export interface Web3CommunityExperience {
  community: string
  role: string
}

interface ProfileForRecruit {
  name: string
  birth: string
  gender: string
  phone: string
}
