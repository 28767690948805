import Footer from '@src/pages/global/Footer'
import SplashPage from './splash/SplashPage'
import CommunityPage from './community/CommunityPage'
import TrustProtocolPage from './trustprotocol/TrustProtocolPage'
import BuildingPlatformPage from './buildingplatform/BuildingPlatformPage'
import SbtPage from './sbt/SbtPage'
import AnimationLogo from '@src/pages/global/AnimationLogo'
import React from 'react'

export default function Home() {
  return (
    <>
      <AnimationLogo />
      <div className="appBody">
        <SplashPage />
        <CommunityPage />
        <TrustProtocolPage />
        <BuildingPlatformPage />
        <SbtPage />
        <Footer />
      </div>
    </>
  )
}
